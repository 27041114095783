<template>
  <div>
    <weekly-dates :date="date"></weekly-dates>
    <schedules ref="schedules" :query="query"></schedules>
    <alarm-modal ref="alarm"></alarm-modal>
  </div>
</template>

<script>
  import WeeklyDates from './WeeklyDates'
  import Schedules from './Schedules'
  import AlarmModal from './AlarmModal'
  export default {
    name: 'CalendarWeekly',
    components: {
      WeeklyDates,
      Schedules,
      AlarmModal
    },
    props: {
      date: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        query: {}
      }
    },
    methods: {
      openAlarm (schedule) {
        this.$refs.alarm.open(schedule.id)
      },
      load (date) {
        this.$refs.schedules.load({
            year: moment(date).format('YYYY'),
            month: moment(date).format('MM'),
            date: moment(date).format('DD')
        })
      }
    },
    mounted () {
      this.load(this.date)
    }
  }
</script>

<style lang="scss" scoped>
</style>
