<template>
  <app-layout id="homework" title="Homework" back>
    <timelines :query="{curriculum_id: $route.params.id, is_homework: true}"></timelines>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Timelines from '../components/Timelines'
  export default {
    name: 'HomeworkRoute',
    components: {
      AppLayout,
      Timelines
    }
  }
</script>

<style scoped>

</style>
