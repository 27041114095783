<template>
  <app-layout id="profile" title="Profile" back>
    <div class="text-center" style="position: relative;">
      <profile-image :src="$root.profileImage" size="122px"></profile-image>
      <button @click="profileImage" class="border border-burgundy d-inline-block rounded-pill picture-edit"></button>
    </div>
    <h4 v-if="$root.user.corp" class="text-center mt-3 mb-4">{{$root.user.corp.name}}</h4>
    <form method="PUT" action="/user" @submit.prevent="save">
      <input ref="profileImage" @change="profilePreview" name="profile_image" type="file" accept="image/png, image/jpeg" style="display: none;"/>
      <div class="form-group">
        <label for="name">회원 이름</label>
        <input v-model="$root.user.name" id="name" name="name" type="text" class="form-control">
      </div>
      <div class="form-group">
        <label for="position">직급</label>
        <input v-model="$root.user.position" id="position" name="position" type="text" class="form-control">
      </div>
      <div class="form-group">
        <label for="job">직책</label>
        <input v-model="$root.user.job" id="job" name="job" type="text" class="form-control">
      </div>
      <div class="form-group">
        <label for="birth">생년월일</label>
        <input v-model="$root.user.birth" id="birth" name="birth" type="date" class="form-control">
      </div>
      <div class="form-group">
        <label for="mobile">휴대전화</label>
        <input v-model="$root.user.mobile" id="mobile" name="mobile" type="text" class="form-control">
      </div>
      <div v-if="false" class="mt-3">
        <label class="d-block">수신동의</label>
        <div class="custom-control custom-checkbox custom-control-inline">
          <input v-model="$root.user.mkt_email_agree" id="mkt-email-agree" type="checkbox" name="mkt_email_agree" value="Y" class="custom-control-input">
          <label class="custom-control-label" for="mkt-email-agree">이메일</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
          <input v-model="$root.user.mkt_sms_agree" id="mkt-sms-agree" type="checkbox" name="mkt_sms_agree" value="Y" class="custom-control-input">
          <label class="custom-control-label" for="mkt-sms-agree">SMS</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
          <input v-model="$root.user.mkt_mobile_agree" id="mkt-mobile-agree" type="checkbox" name="mkt_mobile_agree" value="Y" class="custom-control-input">
          <label class="custom-control-label" for="mkt-mobile-agree">휴대전화</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
          <input v-model="$root.user.mkt_fax_agree" id="mkt-fax-agree" type="checkbox" name="mkt_fax_agree" value="Y" class="custom-control-input">
          <label class="custom-control-label" for="mkt-fax-agree">FAX</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
          <input v-model="$root.user.mkt_corp_tel_agree" id="mkt-corp-tel-agree" type="checkbox" name="mkt_corp_tel_agree" value="Y" class="custom-control-input">
          <label class="custom-control-label" for="mkt-corp-tel-agree">직장전화</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
          <input v-model="$root.user.mkt_corp_addr_agree" id="mkt-corp-addr-agree" type="checkbox" name="mkt_corp_addr_agree" value="Y" class="custom-control-input">
          <label class="custom-control-label" for="mkt-corp-addr-agree">직장주소</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
          <input v-model="$root.user.mkt_visit_agree" id="mkt-visit-agree" type="checkbox" name="mkt_visit_agree" value="Y" class="custom-control-input">
          <label class="custom-control-label" for="mkt-visit-agree">방문</label>
        </div>
      </div>
      <div class="text-center mt-2">
        <router-link to="/alarms" class="btn btn-outline-secondary">알람관리</router-link>
      </div>
      <div class="text-center mt-4">
        <input type="submit" value="저장" class="btn btn-primary"/>
      </div>
    </form>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Alert from '../components/Alert'
  import ProfileImage from "../components/ProfileImage";
  export default {
    name: 'ProfileRoute',
    components: {
      AppLayout,
      ProfileImage,
      Alert
    },
    methods: {
      profileImage () {
        $(this.$refs.profileImage).click()
      },
      profilePreview () {
        this.$root.profileImage = URL.createObjectURL(this.$refs.profileImage.files[0])
      },
      save () {
        var formData = new FormData()
        Object.entries(this.$root.user).forEach(entry => {
          formData.append(entry[0], entry[1])
        })
        if (this.$refs.profileImage.files.length > 0) {
          formData.append('profile_image', this.$refs.profileImage.files[0])
        }
        this.$root.api().post(`/user`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data === true) {
            alert('저장되었습니다.')
          }
        })
      }
    },
    mounted () {
      if (this.$root.user.profile_image) {
        this.$root.profileImage = this.$root.user.profile_image
      }
    }
  }
</script>

<style scoped>
  .picture-edit {
    position: absolute;
    top: 79px;
    right: calc(50% - 70px);
    width: 32px;
    height: 32px;
    background-image: url('/img/pencel.png');
    background-size: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #ffffff;
  }
</style>
