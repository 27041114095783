<template>
  <div class="root" :style="{height: height + 'px'}">
    <div :style="{transition: transition}" class="wheel-container">
      <slot></slot>
    </div>
    <div v-if="guideLine" class="guide-line d-flex flex-column">
      <button type="button" class="navigator navigator-up d-flex w-100 justify-content-end"></button>
      <button type="button" class="navigator navigator-down d-flex w-100 justify-content-end"></button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Wheel',
    props: {
      height: {
        type: Number,
        required: true
      },
      displayCount: {
        type: Number,
        required: true
      },
      vertical: {
        type: Boolean,
        required: false,
        default: false
      },
      horizontal: {
        type: Boolean,
        required: false,
        default: true
      },
      dragGoal: {
        type: Number,
        required: false,
        default: 30
      },
      wheelTriggerGoal: {
        type: Number,
        required: false,
        default: 10
      },
      transitionDuration: {
        type: Number,
        required: false,
        default: 0.3
      },
      radius: {
        type: Number,
        required: false,
        default: 0
      },
      padding: {
        type: Boolean,
        required: false,
        default: false
      },
      guideLine: {
        type: [Boolean, String],
        required: false,
        default: false
      },
      circle: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data () {
      return {
        lock: false,
        startPoint: null,
        endPoint: null,
        container: null,
        count: 0,
        step: 0,
        wheelCount: 0,
        transition: ''
      }
    },
    computed: {
      centerIndex () {
        return Math.floor(this.displayCount / 2)
      },
      itemHeight () {
        return this.height / this.displayCount
      },
      coordinate () {
        return this.step * -1 * this.itemHeight
      }
    },
    methods: {
      unify (e) {
        return e.changedTouches ? e.changedTouches[0] : e
      },
      wheel (e) {
        e.preventDefault()
        if (this.lock === false) {
          if (e.deltaY > 0) {
            this.increaseStep()
          }
          else {
            this.decreaseStep()
          }
          this.transform(this.coordinate)
        }
      },
      startSwipe (e) {
        if (this.vertical) {
          this.startPoint = this.unify(e).clientY
        }
        else {
          this.startPoint = this.unify(e).clientX
        }
      },
      drag (e) {
        e.preventDefault()
        if (this.unify(e).clientY > this.$el.offsetTop + this.$el.offsetHeight) {
          this.endSwipe(e)
          return
        }
        // if (this.startPoint !== null) {
        //     if (this.vertical) {
        //         this.transform(this.coordinate + (this.unify(e).clientY - this.startPoint))
        //     }
        //     else {
        //         this.unify(e).clientX
        //     }
        // }
      },
      endSwipe (e) {
        if (this.vertical) {
          this.endPoint = this.unify(e).clientY
        }
        else {
          this.endPoint = this.unify(e).clientX
        }
        if ((this.startPoint || this.startPoint === 0) && Math.abs(this.startPoint - this.endPoint) > this.dragGoal) {
          if (this.startPoint > this.endPoint) {
            this.increaseStep()
          }
          else {
            this.decreaseStep()
          }
          this.transform(this.coordinate)
        }
        this.startPoint = null
        this.endPoint = null
      },
      increaseStep () {
        if (this.padding) {
          if (this.step < this.count - 1) {
            this.step++
          }
        }
        else {
          if (this.step < (this.count - this.displayCount)) {
            this.step++
          }
        }
      },
      decreaseStep() {
        if (this.step > 0) {
          this.step -= 1
        }
      },
      getItem (index) {
        return this.container.children[index]
      },
      transform (coordinate) {
        return new Promise((resolve, reject) => {
          this.lock = true
          this.container.style.transform = `translateY(${coordinate}px)`
          if (this.step > 0) {
            let item = this.getItem(this.step - 1)
            let radian = (Math.PI / 2 / this.centerIndex) * (this.centerIndex + 1)
            if (this.circle) {
              item.style.transform = `translate(${Math.cos(radian) * this.radius}px, ${-(Math.sin(radian) * this.radius - this.itemHeight * (this.centerIndex + 1))}px)`
            }
            else {
              item.style.transform = `translate(${Math.cos(radian) * this.radius}px) scale(0)`
            }
            item.style.opacity = 0
          }
          for (let index = 0; index < this.displayCount; index++) {
            let item = this.getItem(index + this.step)
            if (!item) continue
            if (index < this.centerIndex) {
              let radian = (Math.PI / 2 / this.centerIndex) * (this.centerIndex - index)
              if (this.circle) {
                item.style.transform = `translate(${Math.cos(radian) * this.radius}px, ${-(Math.sin(radian) * this.radius - this.itemHeight * (this.centerIndex - index))}px)`
              }
              else {
                item.style.transform = `translate(${Math.cos(radian) * this.radius}px) scale(${(index + 1) / (this.centerIndex + 1)})`
              }
              item.style.opacity = (index + 1) / (this.centerIndex + 1)
            }
            else if (index > this.centerIndex) {
              let radian = (Math.PI / 2 / this.centerIndex) * (this.centerIndex - index)
              if (this.circle) {
                item.style.transform = `translate(${Math.cos(radian) * this.radius}px, ${-(Math.sin(radian) * this.radius - this.itemHeight * (this.centerIndex - index))}px)`
              }
              else {
                item.style.transform = `translate(${Math.cos(radian) * this.radius}px) scale(${(this.displayCount - index) / (this.centerIndex + 1)})`
              }
              item.style.opacity = (this.displayCount - index) / (this.centerIndex + 1)
            }
            else {
              if (this.vertical) {
                let radian = (Math.PI / 2 / this.centerIndex) * (this.centerIndex - index)
                item.style.transform = `translate(${Math.cos(radian) * this.radius}px)`
                item.style.opacity = 1
              }
            }
          }
          if (this.step < this.count - 1) {
            let item = this.getItem(this.step + this.displayCount)
            let radian = (Math.PI / 2 / this.centerIndex) * (this.centerIndex + 1)
            if (this.circle) {
              item.style.transform = `translate(${Math.cos(radian) * this.radius}px, ${-(Math.sin(radian) * this.radius - this.itemHeight)}px)`
            }
            else {
              item.style.transform = `translate(${Math.cos(radian) * this.radius}px) scale(0)`
            }
            item.style.opacity = 0
          }
          setTimeout(() => {
            this.lock = false
            if (resolve) {
              resolve()
            }
          }, (this.transitionDuration * 1.1 * 1000))
        })
      },
      load (step) {
        this.container = this.$el.querySelector('.wheel-container')
        let navigator = this.$el.querySelector('.guide-line')
        this.$el.querySelector('.navigator-up').addEventListener('click', () => {
          this.decreaseStep()
          this.transform(this.coordinate)
        })
        this.$el.querySelector('.navigator-down').addEventListener('click', () => {
          this.increaseStep()
          this.transform(this.coordinate)
        })
        navigator.addEventListener('mousedown', this.startSwipe, false)
        navigator.addEventListener('touchstart', this.startSwipe, false)
        navigator.addEventListener('mousemove', this.drag, false)
        navigator.addEventListener('touchmove', this.drag, false)
        navigator.addEventListener('mouseup', this.endSwipe, false)
        navigator.addEventListener('mouseleave', this.endSwipe, false)
        navigator.addEventListener('touchend', this.endSwipe, false)
        this.container.addEventListener('wheel', this.wheel, false)
        let items = this.container.children
        this.count = items.length
        if (this.padding) {
          for (var index = 0; index < this.centerIndex; index++) {
            var topPadding = document.createElement('div')
            var bottomPadding = document.createElement('div')
            this.container.insertBefore(topPadding, items[0])
            this.container.appendChild(bottomPadding)
          }
        }
        for (let index = 0; index < items.length; index++) {
          let item = items[index]
          item.style.height = new String(this.itemHeight) + 'px'
          item.style.transition = `${this.transitionDuration}s ease-out`
          item.classList.add('wheel-item')
        }
        if (this.guideLine) {
          let guideLine = this.$el.querySelector('.guide-line')

          if (this.guideLine === true) {
            guideLine.style.border = 'dashed #000000 1px'
          }
          else {
            guideLine.style.border = this.guideLine
          }
        }
        if (step > 0) {
          this.step = step
          this.transform(this.coordinate).then(() => {
            this.transition = 'transform ' + this.transitionDuration + 's ease-out'
          })
        } else {
          this.transition = 'transform ' + this.transitionDuration + 's ease-out'
          this.transform(0)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  div.root {
    position: relative;
    overflow: hidden;
    will-change: transform;
    perspective: 300px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .wheel-container .wheel-item {
    position: relative;
    scroll-snap-align: start;
    transform-origin-x: left;
    -webkit-transform-origin-x: left;
    -moz-transform-origin: left;
    /*width: fit-content;*/
    width: calc(100% - 100px);
    &:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30%;
      height: 38px;
      background: linear-gradient(to right, rgba(145, 53, 58, 0), rgba(145, 53, 58, 1) 50%);
      font-size: 24px;
    }
  }
  .guide-line {
    position: absolute;
    top: calc(50% - 150px);
    left: -240px;
    width: 300px;
    height: 300px;
    background-color: #682327;
    border-radius: 50%;
    overflow: hidden;
  }
  .navigator {
    flex: 1;
    border: none;
    background-color: transparent;
    margin: 2.5px;
    position: relative;
  }
  .navigator-up:after {
    content: '';
    background-image: url("/img/back_white.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
    position: absolute;
    right: 28px;
    bottom: 25px;
  }
  .navigator-down:after {
    content: '';
    background-image: url("/img/back_white.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(-90deg);
    width: 20px;
    height: 20px;
    position: absolute;
    right: 28px;
    top: 25px;
  }
</style>
