<template>
  <div>
    <div v-if="$root.getQuery('keyword')" class="mb-4">
      <button @click="$root.searchClean()" type="button" class="btn btn-primary">
        {{$root.getQuery('keyword')}}
        <span class="badge badge-light">&times;</span>
      </button>
      검색결과
    </div>
    <ul class="mt-5">
      <li v-for="(student, index) in students" :class="{'mt-3': index === 0, 'mt-4': index > 0}" class="curriculum card">
        <div class="row no-gutters">
          <div>
            <div class="profile-image bg-white-90 rounded-pill border-burgundy border" :style="'background-image: url(' + (student.profile_image || '/img/profile.svg') + ')'"></div>
          </div>
          <div class="col">
            <div class="card-body p-0 pl-3">
              <div class="row">
                <div class="col">
                  <strong style="font-size: 27px;">{{student.name}}</strong>
                  <span class="ml-1" style="font-size: 18px;">{{student.position}}</span>
                </div>
              </div>
              <div class="row mt-1" style="font-size: 15px; line-height: 19px;">
                <div v-html="student.job" class="col"></div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-if="students.length < 1" class="text-center">검색된 수강생이 없습니다.</div>
  </div>
</template>

<script>
  export default {
    name: 'Students',
    props: {
      curriculumId: {
        type: [Number, String],
        required: true
      },
      filters: {
        type: Object
      }
    },
    data () {
      return {
        categories: {},
        students: []
      }
    },
    methods: {
      search () {
        this.$root.api().get(`/curriculums/${this.curriculumId}/students`, {
          params: {
            filters: this.filters
          }
        }).then(response => {
          this.students = response.data.students
        })
      }
    },
    mounted () {
      this.search()
    }
  }
</script>

<style scoped>
  .profile-image {
    width: 70px;
    height: 70px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
</style>
