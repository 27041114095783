/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import 'babel-polyfill'
import Vue from 'vue'
import VCalendar from 'v-calendar'
import VueRouter from 'vue-router'
import Qs from 'qs'

Vue.use(VueRouter)

Vue.use(VCalendar, {
    locales: {
        'ko-KR': {
            firstDayOfWeek: 1,
            masks: {
                title: 'YYYY년 MM월'
            }
        }
    }
})

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

import DashboardRoute from './routes/DashboardRoute'
import CalendarRoute from './routes/CalendarRoute'
import CalendarWeeklyRoute from './routes/CalendarWeeklyRoute'
import SchedulesRoute from './routes/SchedulesRoute'
import ScheduleRoute from './routes/ScheduleRoute'
import CurriculumsRoute from './routes/CurriculumsRoute'
import CurriculumRoute from './routes/CurriculumRoute'
import StudentsRoute from './routes/StudentsRoute'
import PicturesRoute from './routes/PicturesRoute'
import HomeworkRoute from './routes/HomeworkRoute'
import LecturesRoute from './routes/LecturesRoute'
import LectureRoute from './routes/LectureRoute'
import LecturersRoute from './routes/LecturersRoute'
import LecturerRoute from './routes/LecturerRoute'
import AlarmsRoute from './routes/AlarmsRoute'
import TimelinesRoute from './routes/TimelinesRoute'
import TimelineRoute from './routes/TimelineRoute'
import ProfileRoute from './routes/ProfileRoute'
import Modal from './components/Modal'

import AlarmModal from './components/AlarmModal'
import WheelTimelines from './components/WheelTimelines'
import Timelines from './components/Timelines'

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

window.Vue = Vue

document.addEventListener('DOMContentLoaded', () => {
    window.app = new Vue({
        el: '#app',
        router: new VueRouter({
            mode: 'history',
            routes: [
                { path: '/', component: DashboardRoute },
                { path: '/home', component: DashboardRoute },
                { path: '/profile', component: ProfileRoute },
                { path: '/calendar', component: CalendarRoute },
                { path: '/calendar/:date', component: CalendarRoute },
                { path: '/week/:date', component: CalendarWeeklyRoute },
                { path: '/schedules', component: SchedulesRoute },
                { path: '/schedules/:id', component: ScheduleRoute },
                { path: '/curriculums', component: CurriculumsRoute },
                { path: '/curriculums/:id', component: CurriculumRoute },
                { path: '/curriculums/:id/students', component: StudentsRoute },
                { path: '/curriculums/:id/pictures', component: PicturesRoute },
                { path: '/curriculums/:id/homework', component: HomeworkRoute },
                { path: '/lectures', component: LecturesRoute },
                { path: '/lectures/:id', component: LectureRoute },
                { path: '/lecturers', component: LecturersRoute },
                { path: '/lecturers/:id', component: LecturerRoute },
                { path: '/alarms', component: AlarmsRoute },
                { path: '/timelines', component: TimelinesRoute },
                { path: '/timelines/:id', component: TimelineRoute }
            ]
        }),
        components: {
            Modal,
            AlarmModal,
            WheelTimelines,
            Timelines
        },
        data () {
            return {
                user: {},
                profileImage: '/img/profile.svg',
                apiToken: null,
                csrfToken: null
            }
        },
        methods: {
            api () {
                return axios.create({
                    baseURL: 'https://app.innofitpartners.com/api',
                    headers: {
                        Authorization: 'Bearer ' + this.apiToken,
                        Accept: 'application/json'
                    },
                    paramsSerializer: function (params) {
                        return Qs.stringify(params, {arrayFormat: 'brackets'})
                    }
                })
            },
            searchClean () {
                if (confirm('검색결과를 초기화 하시겠습니까?')) {
                    let controller = location.pathname.substr(1).split('/')[0]
                    location.href = `/${controller}`
                }
            },
            getQuery (name) {
                return Qs.parse(location.search.substr(1))[name]
            },
            hasQuery (name) {
                return Qs.parse(location.search.substr(1))[name] !== undefined
            },
            command (name, data) {
                if (window.commandPort) {
                    window.commandPort.postMessage(JSON.stringify({
                        name: name,
                        data: data
                    }))
                } else {
                    setTimeout(() => {
                        this.command(name, data)
                    }, 200)
                }
            },
            isIphoneX () {
                if (!!navigator.userAgent.match(/iPhone/i)) {
                    var iHeight = window.screen.height
                    var iWidth = window.screen.width
                    // Xmax-Xr
                    if (iWidth === 414 && iHeight === 896) {
                        return true
                    }
                    // X-Xs
                    if (iWidth === 375 && iHeight === 812) {
                        return true
                    }
                }
                return false
            }
        },
        created () {
            this.apiToken = document.querySelector('meta[name=api_token]').getAttribute('content')
            this.csrfToken = document.querySelector('meta[name=csrf_token]').getAttribute('content')
            this.api().get('/user').then(response => {
                this.user = response.data
                if (this.user.profile_image) {
                    this.profileImage = this.user.profile_image
                }
                this.command('login', this.user)
            })
        },
        mounted () {
            let calendar = document.querySelector('body #app.calendar .vc-container')
            let query = Qs.parse(location.search.substr(1))
            if (query.keyword) {
                this.searchKeyword = query.keyword
            }
        }
    })
})

$(function(){
    moment.locale('ko-KR')
    $('[data-card-expand="false"]').each(function(index, element){
        $(element).click(() => {
            $(element.getAttribute('data-target')).removeClass('card-expanded')
        })
    })
    $('[data-card-expand="true"]').each(function(index, element){
        $(element).click(() => {
            $('.card-expanded').removeClass('card-expanded')
            $(element.getAttribute('data-target')).addClass('card-expanded')
        })
    })
})
