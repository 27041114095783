<template>
  <div class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div class="modal-content border-0" style="border-radius: 25px;">
        <div class="modal-header">
          <div class="text-center w-100 p-1">댓글 더보기</div>
          <button type="button" class="close position-absolute p-2 btn btn-link" data-dismiss="modal" aria-label="Close" style="right: calc(1rem + 17px); top: calc(1rem + 10px);">
            <img aria-hidden="true" src="/img/close.svg">
          </button>
        </div>
        <div class="modal-body p-0">
          <button @click="modifyComment" class="d-block text-center btn btn-lg w-100 p-4 font-weight-bold">댓글 수정하기</button>
          <button @click="deleteComment" class="d-block text-center btn btn-lg w-100 p-4 font-weight-bold border-top">댓글 삭제하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TimelineCommentManage',
    data () {
      return {
        timeline: null,
        comment: null
      }
    },
    methods: {
      open (timeline, comment) {
        this.timeline = timeline
        this.comment = comment
        $(this.$el).modal('show')
      },
      close () {
        $(this.$el).modal('hide')
        this.timeline = null
        this.comment = null
      },
      modifyComment () {
        this.$emit('modify', this.timeline, this.comment)
        this.close()
      },
      deleteComment () {
        this.$root.api().delete(`/timelines/comments/${this.comment.id}`).then(response => {
          this.$emit('delete', this.timeline, this.comment)
          this.close()
        })
      }
    }
  }
</script>

<style scoped>

</style>
