<template>
<!--  <ul class="mt-5 row">-->
<!--    <li v-for="(picture, index) in pictures" class="picture card col p-0 overflow-hidden m-2">-->
<!--      <img :src="'https://admin.app.innofitpartners.com' + picture.file_path"/>-->
<!--      <img src="/img/samples/lecturer_sample.jpg" width="100%"/>-->
<!--    </li>-->
<!--  </ul>-->
  <div class="row">
    <viewer :images="pictures.map(picture => picture.file_path)" :options="{toolbar: false, navbar: false}">
      <img v-for="picture in pictures" :src="picture.file_path" :key="picture.id" :alt="picture.org_filename" class="col-6 col-md-4">
    </viewer>
  </div>
</template>

<script>
  import 'viewerjs/dist/viewer.css'
  import Viewer from 'v-viewer'
  import Vue from 'vue'
  Vue.use(Viewer)

  export default {
    name: 'Pictures',
    props: {
      curriculumId: {
        type: [Number, String],
        required: true
      },
      filters: {
        type: Object
      }
    },
    data () {
      return {
        categories: {},
        pictures: []
      }
    },
    methods: {
      search () {
        this.$root.api().get(`/curriculums/${this.curriculumId}/pictures`, {
          params: {
            filters: this.filters
          }
        }).then(response => {
          this.pictures = response.data.pictures
        })
      }
    },
    mounted () {
      this.search()
    }
  }
</script>

<style scoped>

</style>
