<template>
  <app-layout id="curriculums" title="Curriculums" back>
    <curriculum :id="parseInt($route.params.id)"></curriculum>
  </app-layout>
</template>

<script>
  import Curriculum from '../components/Curriculum'
  import AppLayout from "../layouts/AppLayout";
  export default {
    name: 'CurriculumRoute',
    components: {
      AppLayout,
      Curriculum
    }
  }
</script>

<style scoped>

</style>
