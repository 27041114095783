<template>
  <div>
    <div class="text-center">
      <div class="profile-image bg-white-90 rounded-pill border-burgundy border mx-auto" :style="'background-image: url(' + (lecturer.profile_image || '/img/profile.svg') + ')'"></div>
    </div>
    <h3 class="text-center font-weight-bolder mt-4 mb-0" style="font-size: 27px;">{{lecturer.name}}</h3>
    <div class="text-center" style="font-size: 18px;">{{categories[lecturer.category]}}</div>

    <div v-if="false" class="review text-center mt-4">
      <div style="font-size: 14px;">강사 평점</div>
      <div class="score font-weight-lighter text-burgundy">4.5</div>
    </div>

    <div class="text-center mt-4">
      <div style="font-size: 14px;">강사 이력</div>
      <div v-html="lecturer.history" class="text-left" style="font-size: 16px;"></div>
    </div>

    <div>
      <div class="font-weight-bold mt-5" style="font-size: 40px;">강사님의 강의 일정을 확인해주세요</div>
      <schedules v-if="$root.user.id" :query="{lecturer_id: id, scheduled: $root.user.id}" expandable></schedules>
    </div>
  </div>
</template>

<script>
  import codes from './Codes.mixin'
  import Schedules from './Schedules'
  export default {
    name: 'Lecturer',
    mixins: [
      codes
    ],
    components: {
      Schedules
    },
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        lecturer: {},
        schedules: [],
        expanded: null,
        categories: {}
      }
    },
    methods: {
      load (id) {
        this.$root.api().get(`/lecturers/${id}`).then(response => {
          this.lecturer = response.data
        })
        this.$root.api().get(`/lectures`, {
          params: {
            lecturer_id: id
          }
        }).then(response => {
          this.schedules = response.data.lectures
        })
      },
      route (schedule, e) {
        if (this.expanded === schedule.schedule_id) {
          this.$router.push(`/schedules/${schedule.schedule_id}`)
        } else {
          e.preventDefault()
          e.stopPropagation()
          this.expanded = schedule.schedule_id
        }
      }
    },
    mounted () {
      this.getCodes('lecture_category').then(response => {
        response.data.forEach(category => {
          this.categories[category.value] = category.name
        })
      })
      this.load(this.id)
    }
  }
</script>

<style scoped>
  .profile-image {
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
</style>
