<template>
  <app-layout id="calendar" title="Calendar">
    <calendar :filters="query"></calendar>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Calendar from '../components/Calendar'
  export default {
    name: 'CalendarRoute',
    components: {
      AppLayout,
      Calendar
    },
    props: {
      query: {
        type: Object,
        required: false,
        default () {
          return this.$route.query
        }
      }
    },
    mounted () {
    }
  }
</script>

<style lang="scss" scoped>

</style>
