<template>
  <app-layout id="lecturers" title="Lecturers" back>
    <lecturer :id="parseInt($route.params.id)"></lecturer>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Lecturer from '../components/Lecturer'
  export default {
    name: 'LecturerRoute',
    components: {
      AppLayout,
      Lecturer
    }
  }
</script>

<style scoped>

</style>
