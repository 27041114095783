<template>
  <app-layout id="calendar" title="Calendar" back :searchable="true">
    <calendar-weekly ref="weekly" :date="$route.params.date"></calendar-weekly>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import CalendarWeekly from '../components/CalendarWeekly'
  export default {
    name: 'CalendarWeeklyRoute',
    components: {
      AppLayout,
      CalendarWeekly
    },
    beforeRouteUpdate (to, from, next) {
      this.$refs.weekly.load(to.params.date)
      next()
    }
  }
</script>

<style scoped>

</style>
