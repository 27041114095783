<template>
  <app-layout id="lectures" title="Lectures">
    <lectures></lectures>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Lectures from '../components/Lectures'
  export default {
    name: 'LecturesRoute',
    components: {
      AppLayout,
      Lectures
    }
  }
</script>

<style scoped>

</style>
