<template>
  <div>
    <div v-if="$root.getQuery('keyword')" class="mb-4">
      <button @click="$root.searchClean()" type="button" class="btn btn-primary">
        {{$root.getQuery('keyword')}}
        <span class="badge badge-light">&times;</span>
      </button>
      검색결과
    </div>
    <h2>
      <select v-model="category" @change="searchCategory" class="category-select custom-select font-weight-bold text-burgundy border-0 bg-transparent">
        <option value="">전체</option>
        <option v-for="(label, value) in categories" :value="value">{{label}}</option>
      </select>
    </h2>
    <ul class="mt-5">
      <li v-for="(curriculum, index) in curriculums" :class="{'mt-3': index === 0, 'mt-4': index > 0}" class="curriculum card">
        <router-link :to="'/curriculums/' + curriculum.id">
          <span v-if="curriculum.process" :class="{'bg-white text-burgundy': curriculum.process === 'RECRUIT'}"
                class="badge badge-pill badge-primary border border-burgundy" style="font-size: 13px;">
            {{curriculumProcesses[curriculum.process]}}
          </span>
          <div class="font-weight-bolder" style="font-size: 25px;">{{curriculum.title}}</div>
          <div v-if="curriculum.start_at && curriculum.end_at">{{curriculum.start_at.substr(5, 5).replace('-', '.')}}~{{curriculum.end_at.substr(5, 5).replace('-', '.')}}</div>
          <div v-html="curriculum.description" class=" description mt-3" style="font-size: 18px;"></div>
        </router-link>
      </li>
    </ul>
    <div v-if="curriculums.length < 1" class="text-center">검색된 과정이 없습니다.</div>
  </div>
</template>

<script>
  import codes from './Codes.mixin'
  export default {
    name: 'Curriculums',
    mixins: [
      codes
    ],
    data () {
      return {
        category: '',
        categories: {},
        curriculums: []
      }
    },
    methods: {
      search () {
        if (this.$route.query) {
          this.category = this.$route.query.category || ''
        }

        this.$root.api().get(`/curriculums`, {
          params: this.$route.query
        }).then(response => {
          this.curriculums = response.data.curriculums
        })
      },
      searchCategory () {
        var query = Object.assign({}, this.$route.query)
        query.category = this.category
        this.$router.push({
          query: query
        })
      }
    },
    mounted () {
      this.getCodes('lecture_category').then(response => {
        response.data.forEach(category => {
          this.categories[category.value] = category.name
        })

        this.search()
      })
    },
    watch: {
      $route () {
        this.search()
      }
    }
  }
</script>

<style scoped>
  .category-select {
    height: calc(2.6em + 0.75rem + 2px);
    font-size: 30px;
    border-bottom: solid #91353a 3px !important;
    border-radius: 0;
  }
</style>
