<template>
  <app-layout id="students" title="Students" back>
    <students :curriculum-id="$route.params.id" :filters="$route.query"></students>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Students from '../components/Students'
  export default {
    name: "StudentsRoute",
    components: {
      AppLayout,
      Students
    }
  }
</script>

<style scoped>

</style>
