<template>
  <div>
    <div v-if="$root.getQuery('keyword')" class="mb-4">
      <button @click="$root.searchClean()" type="button" class="btn btn-primary">
        {{$root.getQuery('keyword')}}
        <span class="badge badge-light">&times;</span>
      </button>
      검색결과
    </div>
    <select v-model="category" @change="searchCategory" class="category-select custom-select font-weight-bold text-burgundy border-0 bg-transparent">
      <option value="">전체</option>
      <option v-for="(label, value) in categories" :value="value">{{label}}</option>
    </select>
    <ul class="mt-5">
      <li v-for="(lecturer, index) in lecturers" :class="{'mt-3': index === 0, 'mt-4': index > 0}" class="curriculum card">
        <router-link :to="'/lecturers/' + lecturer.id" class="row no-gutters">
          <div>
            <div class="profile-image bg-white-90 rounded-pill border-burgundy border" :style="'background-image: url(' + (lecturer.profile_image || '/img/profile.svg') + ')'"></div>
          </div>
          <div class="col">
            <div class="card-body p-0 pl-3">
              <div class="row">
                <div class="col">
                  <strong style="font-size: 27px;">{{lecturer.name}}</strong>
                  <span class="ml-1" style="font-size: 18px;">{{categories[lecturer.category]}}</span>
                </div>
              </div>
              <div class="row mt-1" style="font-size: 15px; line-height: 19px;">
                <div v-html="lecturer.intro" class="col"></div>
              </div>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
    <div v-if="lecturers.length < 1" class="text-center">검색된 강사가 없습니다.</div>
  </div>
</template>

<script>
  import codes from './Codes.mixin'
  export default {
    name: 'Lecturers',
    mixins: [
      codes
    ],
    data () {
      return {
        category: '',
        categories: {},
        lecturers: []
      }
    },
    methods: {
      search () {
        if (this.$route.query) {
          this.category = this.$route.query.category || ''
        }

        this.$root.api().get(`/lecturers`, {
          params: this.$route.query
        }).then(response => {
          this.lecturers = response.data.lecturers
        })
      },
      searchCategory () {
        var query = Object.assign({}, this.$route.query)
        query.category = this.category
        this.$router.push({
          query: query
        })
      }
    },
    mounted () {
      this.getCodes('lecture_category').then(response => {
        response.data.forEach(category => {
          this.categories[category.value] = category.name
        })

        this.search()
      })
    },
    watch: {
      $route () {
        this.search()
      }
    }
  }
</script>

<style scoped>
  .profile-image {
    width: 90px;
    height: 90px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .category-select {
    height: calc(2.6em + 0.75rem + 2px);
    font-size: 30px;
    border-bottom: solid #91353a 3px !important;
    border-radius: 0;
  }
</style>
