<template>
  <app-layout id="lectures" title="Lectures" back>
    <lecture ref="lecture" :id="parseInt($route.params.id)"></lecture>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Lecture from '../components/Lecture'
  export default {
    name: 'LectureRoute',
    components: {
      AppLayout,
      Lecture
    },
    beforeRouteUpdate (to, from, next) {
      this.$refs.lecture.load(to.params.id)
      next()
    }
  }
</script>

<style scoped>

</style>
