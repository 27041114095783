<template>
  <div>
    <ul>
      <li v-for="(alarm, index) in alarms" :key="index" @click="setting(alarm)" class="card">
        <div class="row">
          <div class="col-9 text-left">
            <strong style="font-size: 20px;">{{alarm.title}}</strong>
            <div v-if="alarm.schedule_curriculum_title">{{alarm.schedule_curriculum_title}}</div>
            <div v-if="alarm.start_at && alarm.end_at" class="mt-2" style="color: grey;">
              <div v-if="alarm.schedule_id > 0">
                <span class="mr-1">{{alarm.start_at.substr(0, 10).replace(/-/g, '.')}}</span>
                {{alarm.start_at.substr(11, 5)}} ~ {{alarm.end_at.substr(11, 5)}}</div>
              <div v-else>{{alarm.start_at.substr(0, 10)}} ~ {{alarm.end_at.substr(0, 10)}}</div>
            </div>
          </div>
          <div class="col-3 text-right">
            <div class="custom-control custom-switch custom-switch-lg custom-switch-green">
              <input v-model="alarm.is_active" true-value="Y" false-value="N" @click.stop="toggle(alarm)" :id="'schedule-' + alarm.schedule_id" type="checkbox" class="custom-control-input">
              <label @click.stop class="custom-control-label" :for="'schedule-' + alarm.schedule_id"></label>
            </div>
          </div>
        </div>
      </li>
      <li v-if="alarms.length < 1">
        <div class="title font-weight-bold text-center" style="font-size: 20px;">설정된 알람이 없습니다</div>
      </li>
    </ul>
    <alarm-modal ref="setting"></alarm-modal>
  </div>
</template>

<script>
  import AlarmModal from './AlarmModal'
  export default {
    name: 'Alarms',
    components: {
      AlarmModal
    },
    data () {
      return {
        alarms: []
      }
    },
    methods: {
      setting (alarm) {
        if (alarm.schedule_id) {
          this.$refs.setting.open(alarm.schedule_id)
        } else {
          this.$refs.setting.openCurriculum(alarm.curriculum_id)
        }
      },
      toggle (alarm) {
        alarm.is_active = (alarm.is_active === 'Y' ? 'N' : 'Y');
        this.$root.api().put(`/alarms/${alarm.id}`, alarm)
      }
    },
    mounted () {
      this.$root.api().get(`/alarms`).then(response => {
        this.alarms = response.data
      })
    }
  }
</script>

<style scoped>

</style>
