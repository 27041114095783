<template>
  <app-layout id="schedule" title="Schedule" back>
    <schedule :id="parseInt($route.params.id)"></schedule>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Schedule from '../components/Schedule'
  export default {
    name: 'ScheduleRoute',
    components: {
      AppLayout,
      Schedule
    }
  }
</script>

<style scoped>

</style>
