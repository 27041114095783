<template>
  <wheel ref="wheel" :height="310" :display-count="5" :radius="100" padding vertical class="articles" guide-line="none">
    <div v-show="loaded" v-if="timelines.length < 1" style="line-height: 100px; font-size: 23px;">작성된 타임라인이 없습니다.</div>
    <div v-for="timeline in timelines" class="article">
      <div class="overflow-hidden">
        <router-link :to="'/timelines/' + timeline.id" class="text-white">
          <div class="date" v-html="(timeline.regist_at || timeline.created_at).substr(0, 10).replace(/-/g, '.')"></div>
          <div class="subject" v-html="timeline.content"></div>
        </router-link>
      </div>
    </div>
  </wheel>
</template>

<script>
  import Wheel from './Wheel';
  export default {
    name: 'WheelTimelines',
    components: {
      Wheel
    },
    data () {
      return {
        timelines: [],
        loaded: false
      }
    },
    mounted () {
      this.$root.api().get(`/timelines`, {
        params: {
          dashboard: true,
          scheduled: true,
          limit: 10
        }
      }).then(response => {
        this.timelines = response.data.timelines
        this.timelines.push({
          id: '',
          regist_at: '&nbsp;',
          content: '모두보기'
        })
        this.$nextTick(() => {
          this.$refs.wheel.load()
          this.loaded = true
        })
      })
    }
  }
</script>

<style lang="scss" scoped>
  .article:before {
    content: '';
    display: block;
    position: absolute;
    top: 38px;
    left: -20px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #682327;
  }
  .article .subject {
    position: relative;
    overflow: visible;
    font-size: 20px;
    font-weight: bold;
    line-height: 38px;
    height: 38px;
  }
</style>
