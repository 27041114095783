<template>
  <div v-if="active" @scroll.stop="" class="menu">
    <div class="profile">
      <router-link to="/profile">
        <profile-image :src="$root.profileImage" size="100px"></profile-image>
      </router-link>
      <div class="description">
        <router-link to="/profile" class="name">{{$root.user.name}}</router-link>
        <span class="separator">|</span>
        <strong class="job">{{$root.user.job}}</strong>
      </div>
    </div>
    <div class="mt-4">
      <div class="input-group m-auto" style="width: calc(100% - 50px);">
        <input v-model="searchKeyword" @keypress.enter="search" type="text" class="form-control bg-white border-0 rounded-0 p-4" style="font-size: 20px;"/>
        <div class="input-group-append">
          <span class="input-group-text rounded-0 bg-white border-0">
            <input @click="search" type="image" src="/img/magnifier.svg"/>
          </span>
        </div>
      </div>
    </div>
    <ul class="d-flex flex-column">
      <li v-if="$root.user.email === '!vaxy@ntoppings.com'">
        <a href="#" @click.prevent="fcmTest">FCM</a>
      </li>
      <li v-if="$root.user.email === '!vaxy@ntoppings.com'">
        <a href="#" @click.prevent="downloadTest">Download</a>
      </li>
      <li>
        <router-link to="/" :class="{active: $route.path === '/'}">
          <span class="mdi mdi-home-outline mr-2"></span> 홈
        </router-link>
      </li>
      <li>
        <router-link to="/calendar" :class="{active: $route.path.match(/^\/calendar/)}">
          <span class="mdi mdi-calendar-blank mr-2"></span> 일정관리
        </router-link>
      </li>
      <li>
        <router-link to="/curriculums" :class="{active: $route.path.match(/^\/curriculums/)}">
          <span class="mdi mdi-bookshelf mr-2"></span> 과정안내
        </router-link>
      </li>
      <li v-if="$root.user.email === '!vaxy@ntoppings.com'">
        <router-link to="/lectures" :class="{active: $route.path.match(/^\/lectures/)}">강의</router-link>
      </li>
      <li v-if="$root.user.email === '!vaxy@ntoppings.com'">
        <router-link to="/lecturers" :class="{active: $route.path.match(/^\/lecturers/)}">강사</router-link>
      </li>
      <li style="margin-top: 100px;">
        <a href="/logout">
          <span class="mdi mdi-logout mr-2"></span> 로그아웃
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import ProfileImage from './ProfileImage'
  export default {
    name: 'AppMenu',
    components: {
      ProfileImage
    },
    props: {
      active: Boolean
    },
    data () {
      return {
        searchKeyword: ''
      }
    },
    methods: {
      fcmTest () {
        this.$root.api().post('/app/fcm', {
          title: 'Title',
          message: '테스트 메시지',
          link: 'https://app.innofitpartners.com/calendar'
        })
      },
      downloadTest () {
        this.$root.command('download', {
          url: 'https://app.innofitpartners.com/storage/profile_image/NECb9JTxY7MXYSjjY9HC0q5vumhD6OlkzUGDz6dC.jpeg'
        })
      },
      search () {
        let controller = location.pathname.substr(1).split('/')[0]
        location.href = `/${controller}?keyword=${this.searchKeyword}`
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../sass/app';
  .menu {
    height: 100vh;
    width: calc(100vw - 50px);
    background-color: $burgundy;
    color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow: scroll;

    & a {
      color: $white;
    }

    .slide-enter-active {
      transition: all .3s ease;
    }
    &.slide-enter-active {
      transition: all .3s ease;
    }
    &.slide-leave-active {
      transition: all .3s ease;
    }
    & > .profile {
      text-align: center;
      margin-top: 40px;
      & > a {
        & > img {
          width: 117px;
        }
      }
      & > .description {
        margin-top: 20px;
        font-size: 20px;
        & > .separator {
          margin-left: 5px;
        }
        & > .job {
          margin-left: 5px;
        }
      }
    }
    &.slide-enter {
      transform: translateX(-90vw);
    }
    &.slide-leave-to {
      transform: translateX(-90vw);
    }
  }
  ul {
    margin: 30px 0 0 0;
    a {
      color: $white;
      display: block;
      font-size: 25px;
      font-weight: bold;
      padding: 20px;
      &:hover {
        color: $white;
        background-color: rgba(0, 0, 0, 0.1);
      }
      &:active {
        color: $white;
        background-color: rgba(0, 0, 0, 0.2);
      }
      &.active {
        color: $white;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
  .profile-image {
    width: 117px;
    height: 117px;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
</style>
