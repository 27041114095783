<template>
  <app-layout id="timeline" title="Timeline" back>
    <timelines ref="timelines" :id="parseInt($route.params.id)"></timelines>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Timelines from '../components/Timelines'
  export default {
    name: 'TimelineRoute',
    components: {
      AppLayout,
      Timelines
    },
    beforeRouteUpdate (to, from, next) {
      this.$refs.timelines.load(to.query)
      next()
    }
  }
</script>

<style scoped>

</style>
