var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modal",attrs:{"title":"알람 설정"},on:{"confirm":_vm.confirm}},[[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":"/img/clock.svg","width":"100"}})]),_vm._v(" "),_c('div',{staticClass:"font-weight-bold mt-4",staticStyle:{"font-size":"20px"}},[_vm._v("강의 시작 전")]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-between flex-wrap mt-2"},[_c('button',{staticClass:"btn flex-fill m-1",class:{
          'btn-primary': _vm.alarm.time === '1week',
          'btn-outline-secondary': _vm.alarm.time !== '1week'
        },attrs:{"type":"button"},on:{"click":function($event){_vm.alarm.time = '1week'}}},[_vm._v("일주일 전")]),_vm._v(" "),_c('button',{staticClass:"btn flex-fill m-1",class:{
          'btn-primary': _vm.alarm.time === '1day',
          'btn-outline-secondary': _vm.alarm.time !== '1day'
        },attrs:{"type":"button"},on:{"click":function($event){_vm.alarm.time = '1day'}}},[_vm._v("1일 전")]),_vm._v(" "),_c('button',{staticClass:"btn flex-fill m-1",class:{
          'btn-primary': _vm.alarm.time === '2hour',
          'btn-outline-secondary': _vm.alarm.time !== '2hour'
        },attrs:{"type":"button"},on:{"click":function($event){_vm.alarm.time = '2hour'}}},[_vm._v("2시간 전")]),_vm._v(" "),_c('button',{staticClass:"btn flex-fill m-1",class:{
          'btn-primary': _vm.alarm.time === '1hour',
          'btn-outline-secondary': _vm.alarm.time !== '1hour'
        },attrs:{"type":"button"},on:{"click":function($event){_vm.alarm.time = '1hour'}}},[_vm._v("1시간 전")]),_vm._v(" "),_c('button',{staticClass:"btn flex-fill m-1",class:{
          'btn-primary': _vm.alarm.time === '30minute',
          'btn-outline-secondary': _vm.alarm.time !== '30minute'
        },attrs:{"type":"button"},on:{"click":function($event){_vm.alarm.time = '30minute'}}},[_vm._v("30분 전")])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }