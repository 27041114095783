<template>
  <app-layout id="lecturers" title="Lecturers">
    <lecturers></lecturers>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Lecturers from '../components/Lecturers'
  export default {
    name: 'LecturersRoute',
    components: {
      AppLayout,
      Lecturers
    }
  }
</script>

<style scoped>

</style>
