<template>
  <div>
    <div class="card">
      <div>
        <span v-if="lecture.in_classes"
              class="badge badge-pill badge-primary border border-burgundy" style="font-size: 13px;">
          수강중
        </span>
        <span class="float-right" style="font-size: 18px;">{{categories[lecture.category]}}</span>
      </div>
      <h3 class="mt-3 font-weight-bold" style="font-size: 42px;">{{lecture.title}}</h3>
      <div class="text-center font-weight-bold">{{lecture.creator}}</div>
      <div v-if="false" class="review text-center">
        <div class="title mt-4">강의 평점</div>
        <div v-if="lecture.average > 0" class="score font-weight-lighter text-primary">{{lecture.average}}</div>
        <div v-else class="score font-weight-lighter text-primary">-</div>
      </div>
      <div class="text-center mt-3" v-html="lecture.description"></div>
    </div>
    <schedules v-if="$root.user.id" :query="{lecture_id: id, scheduled: $root.user.id}" expandable></schedules>
  </div>
</template>

<script>
  import AlarmModal from './AlarmModal'
  import Codes from './Codes.mixin'
  import Schedules from './Schedules'
  export default {
    name: 'Lecture',
    components: {
      Schedules,
      AlarmModal
    },
    mixins: [
      Codes
    ],
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        lecture: {},
        categories: {}
      }
    },
    methods: {
      load (id) {
        this.$root.api().get(`/lectures/${id}`).then(response => {
          this.lecture = response.data
        })
      }
    },
    mounted () {
      this.getCodes('lecture_category').then(response => {
        response.data.forEach(code => {
          this.categories[code.value] = code.name
        })
        this.load(this.id)
      })
    }
  }
</script>

<style scoped>

</style>
