<template>
  <div class="modal fade" tabindex="-1" role="dialog" :aria-labelledby="title" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered m-auto" role="document" style="max-width: 340px;">
      <div class="modal-content border-0" style="border-radius: 25px;">
        <div class="modal-header border-bottom-0 d-block">
          <h5 class="modal-title w-100 text-center font-weight-bolder" style="font-size: 27px;">{{title}}</h5>
          <button type="button" class="position-absolute p-2 close btn btn-link" data-dismiss="modal" aria-label="닫기" style="right: calc(1rem + 17px); top: calc(1rem + 15px);">
            <img aria-hidden="true" src="/img/close.svg"/>
          </button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer border-top-0 justify-content-center">
          <button @click="$emit('confirm')" type="button" class="btn btn-primary btn-lg btn-block">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Modal',
    props: {
        title: String
    },
    methods: {
      open () {
        $(this.$el).modal('show')
      },
      close () {
        $(this.$el).modal('hide')
      }
    },
    mounted () {
      $(this.$el).on('show.bs.modal', e => {
        this.$emit('open')
      })
      $(this.$el).on('hide.bs.modal', e => {
        this.$emit('close')
      })
    }
  }
</script>

<style scoped>

</style>
