<template>
  <app-layout id="schedules" title="Schedules" back>
    <div class="text-center">
      <div v-if="$route.query.category">카테고리 : {{$route.query.category}}</div>
      <div v-if="$route.query.lecturer_id">
        강사 :
        <router-link :to="'/lecturers/' + lecturer.id" class="btn btn-outline-primary btn-sm">{{lecturer.name}}</router-link>
      </div>
      <div v-if="$route.query.curriculum_id">
        과정 :
        <router-link :to="'/curriculums/' + curriculum.id" class="btn btn-outline-primary btn-sm">{{curriculum.title}}</router-link>
      </div>
    </div>
    <schedules :query="$route.query"></schedules>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Schedules from '../components/Schedules'
  export default {
    name: 'SchedulesRoute',
    components: {
      AppLayout,
      Schedules
    },
    data () {
      return {
        lecturer: {},
        curriculum: {}
      }
    },
    mounted () {
      if (this.$route.query.lecturer_id) {
        this.$root.api().get(`/lecturers/${this.$route.query.lecturer_id}`).then(response => {
          this.lecturer = response.data
        })
      }
      if (this.$route.query.curriculum_id) {
        this.$root.api().get(`/curriculums/${this.$route.query.curriculum_id}`).then(response => {
          this.curriculum = response.data.curriculum
        })
      }
    }
  }
</script>

<style scoped>

</style>
