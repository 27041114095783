<template>
  <div class="calendar">
    <v-date-picker
      ref="calendar"
      is-required is-expanded is-inline locale="ko-KR" color="red"
      v-model="datetime" :attributes="attributes" @update:to-page="updateCalendar"
    ></v-date-picker>
    <div v-if="$root.getQuery('keyword')" class="mb-4">
      <button @click="$root.searchClean()" type="button" class="btn btn-primary">
        {{$root.getQuery('keyword')}}
        <span class="badge badge-light">&times;</span>
      </button>
      검색결과
    </div>
    <div class="schedule">
      <schedules ref="schedules"></schedules>
    </div>
  </div>
</template>

<script>
  import Schedules from './Schedules'
  export default {
    name: 'Calendar',
    components: {
      Schedules
    },
    props: {
      filters: {
        type: Object
      }
    },
    data () {
      return {
        year: null,
        month: null,
        date: null,
        datetime: null,
        schedules: [],
        attributes: [],
        alarm: {
          scheduleId: null,
          time: null
        }
      }
    },
    methods: {
      setDate (datetime) {
        this.datetime = datetime
      },
      fetchDate (datetime) {
        let date = moment(datetime)
        this.$refs.schedules.load({
          year: date.format('YYYY'),
          month: date.format('MM'),
          date: date.format('DD')
        })

        let _location = `/calendar/${date.format('YYYYMMDD')}`
        if (_location !== (location.pathname + location.search)) {
          this.$router.push(_location)
        }
      },
      updateCalendar (page) {
        if (page.year) {
          this.getSchedules(page.year, page.month)
        }
      },
      getSchedules (year, month) {
        if (parseInt(this.year) != parseInt(year) || parseInt(this.month) != parseInt(month)) {
          this.year = year
          this.month = month
          this.$root.api().get(`/schedules`, {
            params: {
              scheduled: true,
              year: year,
              month: month
            }
          }).then(response => {
            this.attributes.splice(0)
            response.data.schedules.forEach((schedule, index) => {
              if (index < 4) {
                this.attributes.push({
                  dot: 'red',
                  dates: [moment(schedule.start_at).toDate()]
                })
              }
            })
          })
        }
      },
      search () {
        this.$root.api().get(`/schedules`, {
          params: {
            scheduled: true,
            filters: this.filters
          }
        }).then(response => {
          this.schedules = response.data.schedules
        })
      }
    },
    watch: {
      datetime (newVal, oldVal) {
        this.fetchDate(newVal)
      }
    },
    mounted () {
      this.getSchedules(moment().format('YYYY'), moment().format('MM'))
      if (this.filters.keyword) {
        this.search()
      } else {
        let date = this.$route.params.date
        if (date) {
          this.setDate(moment(date).toDate())
          this.fetchDate(this.datetime)
        } else {
          this.setDate(new Date())
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .calendar {
    // 스케쥴 영역
    .schedule {
      & > ul {
        & > li {
          & > a {
            & > .title {
              font-size: 25px;
              line-height: 31px;
            }
            & > .period {
              font-size: 15px;
              line-height: 19px;
            }
            & > .article {
              font-size: 16px;
              line-height: 19px;
              margin-top: 12px;
            }
          }
        }
      }
    }

    // 스케쥴 상세 하단
    .course {
      & > .subject {
        & > .title {
          font-size: 36px;
        }
        & > .curriculum-title {
          font-size: 20px;
        }
      }
      & > .attach {
        width: 100%;
        height: auto;
      }
    }

    .wheel {
      margin-top: 50px;
      height: 80px;
      overflow: hidden;
      &:after {
        content: '';
        display: block;
        width: 400px;
        height: 300px;
        margin-left: calc(50% - 200px);
        border: dashed #91353a 1px;
        border-radius: 50%;
      }
    }
  }
</style>

<style lang="scss">
  @import '../../sass/variables';
  .calendar {
    // 캘린더 영역
    .vc-container {
      margin: auto;
      border: none;
      background-color: transparent;
      --day-min-height: 40px;
      --day-content-width: 40px;
      --day-content-height: 40px;
      --highlight-height: 40px;
      .vc-header {
        padding-top: 0;
      }
      .vc-title {
        font-family: $font-family-sans-serif;
        font-size: 30px;
        margin-top: 6px;
        color: #1e1f29;
      }
      .vc-arrows-container {
        top: 11px;
      }
      .vc-grid-container.vc-weeks {
        margin-top: 20px;
      }
      .vc-weekday {
        font-size: 20px;
        color: #1e1f29;
      }
      .vc-highlight {
        background-color: $burgundy;
      }
      .vc-day-content {
        font-size: 16px;
        color: #707070;
      }
      .vc-day-content:focus {
        --day-content-bg-color-focus: #91353a;
        color: #fbfbfb !important;
      }
      .vc-highlights~.vc-day-content {
        font-size: 20px;
        color: #fbfbfb;
      }
      .vc-dots {
        .vc-dot {
          margin-top: -8px;
        }
        .vc-bg-red-600 {
          background-color: $burgundy;
        }
      }
    }
  }
</style>
