<template>
  <div class="profile-image bg-white-90 rounded-pill border-burgundy border" :style="style">
    <img :src="this.src || '/img/profile.svg'" width="100%" height="100%" style="opacity: 0;">
  </div>
</template>

<script>
  export default {
    name: 'ProfileImage',
    props: {
      src: {
        type: String,
        required: false
      },
      size: {
        type: String,
        required: true
      },
      display: {
        type: String,
        default () {
          return 'block'
        }
      },
      margin: {
        type: String,
        default () {
          return 'auto'
        }
      }
    },
    computed: {
      style () {
        return {
          'background-image': `url(${this.src || '/img/profile.svg'})`,
          'background-size': 'cover',
          margin: this.margin,
          width: this.size,
          height: this.size,
          display: this.display
        }
      }
    }
  }
</script>

<style scoped>
  .profile-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
</style>
