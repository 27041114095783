<template>
  <div>
    <ul v-if="schedules.length > 0">
      <li v-for="(schedule, index) in schedules" :key="index">
        <div @click="route($event, schedule)" class="card" style="cursor: pointer;">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              강사 | <strong>{{schedule.lecturer_name}}</strong>
            </div>
            <button v-if="!isPast(schedule.end_at)" @click.prevent.stop="openAlarm(schedule)" type="button" class="btn btn-clock">알람</button>
          </div>
          <div class="row">
            <div class="col-12 title font-weight-bold" style="font-size: 25px;">{{schedule.title}}</div>
          </div>
          <div v-if="schedule.start_at" class="mt-1">
            날짜 | <strong>{{scheduleDate(schedule.start_at)}}</strong>
          </div>
          <div v-if="schedule.start_at" class="mt-1">
            시간 | <strong>{{schedule.start_at.substr(11, 5)}} ~ {{schedule.end_at.substr(11, 5)}}</strong>
          </div>
          <div class="mt-1">
            장소 | <strong>{{schedule.place}}</strong>
          </div>
          <div v-if="!expandable || expanded === schedule.id" class="article mt-3" v-html="schedule.description"></div>
          <div v-if="expandable && expanded !== schedule.id" class="text-center mt-1">
            <button @click.stop.prevent="expanded = schedule.id" type="button" class="btn p-0">
              <img src="/img/arrow_down.svg"/>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <div v-else class="mt-5">
      <div class="title font-weight-bold text-center" style="font-size: 23px;">일정이 없습니다</div>
    </div>
    <alarm-modal ref="modal"></alarm-modal>
  </div>
</template>

<script>
  import AlarmModal from './AlarmModal'
  export default {
    name: 'Schedules',
    components: {
      AlarmModal
    },
    props: {
      query: {
        type: Object,
        required: false
      },
      expandable: {
        type: Boolean
      }
    },
    data () {
      return {
        schedules: [],
        expanded: null
      }
    },
    methods: {
      openAlarm (schedule) {
        this.$refs.modal.open(schedule.id)
      },
      isPast (datetime) {
        return moment(datetime).valueOf() < (new Date()).valueOf()
      },
      scheduleDate (date) {
        return moment(date).format('YYYY-MM-DD(ddd)')
      },
      load (query) {
        this.schedules.splice(0)
        this.$root.api().get(`/schedules`, {
          params: Object.assign({
            scheduled: true
          }, query)
        }).then(response => {
          this.schedules = response.data.schedules
        })
      },
      route (event, schedule) {
        if (!this.expandable || this.expanded === schedule.id) {
          this.$router.push('/schedules/' + schedule.id)
        } else {
          event.preventDefault()
          event.stopPropagation()
          this.expanded = schedule.id
        }
      }
    },
    mounted () {
      if (this.query && Object.values(this.query).length > 0) {
        this.load(this.query)
      }
    }
  }
</script>

<style scoped>

</style>
