<template>
  <div>
    <ul>
      <li v-for="timeline in timelines" class="card">
        <div>
          <span class="badge badge-pill badge-outline-burgundy px-3 py-1" style="font-size: 14px;">{{categories[timeline.category]}}</span>
          <div class="mt-2" style="font-size: 13px;">{{timeline.created_at.substr(0, 10)}}</div>
          <div class="text-right mt-3" style="font-size: 13px;">
            <span>작성자 |</span>
            <strong>{{timeline.register_name}}</strong>
          </div>
        </div>
        <div v-html="timeline.content.replace(/\n/g, '<br>')" class="mt-3" style="font-size: 14px;"></div>
        <div v-if="false && withRelated">
          <hr class="mt-5 content-related-lecture"/>
          <ul class="mt-3">
            <li>
              <strong>
                <a href="">혁신의 미래</a>
              </strong>
            </li>
            <li class="mt-2">
              <strong>
                <a href="">혁신 미래와 비전</a>
              </strong>
            </li>
          </ul>
        </div>
        <ul class="border-top mt-4" style="margin: 0 -20px;">
          <li v-for="(comment, index) in timeline.comments" :class="{'border-top': index > 0}" style="margin: 0 20px; padding: 20px 0;">
            <div class="row">
              <div style="width: 60px; padding-left: 15px;">
                <profile-image :src="comment.profile_image" size="46px"></profile-image>
              </div>
              <div class="col">
                <div class="mt-1">
                  <strong>{{comment.user_name}}</strong>
                </div>
                <div class="mt-n1" style="color: #6c757d; font-size: 11px;">{{comment.created_at.substr(2, 14).replace(/-/g, '.')}}</div>
              </div>
              <div v-if="$root.user.id === comment.created_id" class="col-2 text-right">
                <button @click="$refs.timelineCommentManage.open(timeline, comment)" type="button" class="btn btn-link p-0">
                  <img src="/img/more.svg"/>
                </button>
              </div>
            </div>
            <div class="row mt-2">
              <div v-html="comment.contents.replace(/\n/g, '<br>')" class="col" style="font-size: 13px;"></div>
            </div>
          </li>
        </ul>
        <div :class="{'border-top': timeline.comments.length > 0}" class="mt-1 text-center">
          <button @click="writeComment(timeline)" type="button" class="btn btn-primary mt-3">댓글달기</button>
        </div>
      </li>
      <li v-if="timelines.length < 1" style="text-align: center;">
        검색된 결과가 없습니다.
      </li>
    </ul>
    <timeline-comment-manage ref="timelineCommentManage" @modify="writeComment" @delete="fetchComments"></timeline-comment-manage>
    <timeline-comment-writer ref="timelineCommentWriter" @save="fetchComments"></timeline-comment-writer>
    <div v-if="id > 0" class="text-center">
      <router-link to="/timelines" class="btn btn-outline-primary">모두보기</router-link>
    </div>
  </div>
</template>

<script>
  import TimelineCommentWriter from './TimelineCommentWriter'
  import TimelineCommentManage from './TimelineCommentManage'
  import ProfileImage from './ProfileImage'
  export default {
    name: 'Timelines',
    components: {
      ProfileImage,
      TimelineCommentManage,
      TimelineCommentWriter
    },
    props: {
      id: {
        type: Number
      },
      query: {
        type: Object,
        default () {
          return {}
        }
      },
      withRelated: {
        type: Boolean
      }
    },
    data () {
      return {
        limit: 3,
        timelines: [],
        categories: {
          schedules: '일정',
          curriculums: '과정',
          lectures: '강의',
          lecturers: '강사'
        }
      }
    },
    methods: {
      writeComment (timeline, comment) {
        this.$refs.timelineCommentWriter.open(timeline, comment)
      },
      fetchComments (timeline, comment) {
        this.$root.api().get(`/timelines/comments`, {
          params: {
            timeline_id: timeline.id
          }
        }).then(response => {
          timeline.comments.splice(0)
          response.data.forEach(comment => {
            timeline.comments.push(comment)
          })
        })
      },
      clear () {
        this.timelines.splice(0)
      },
      load (query, id) {
        query = Object.assign(this.query, query)
        let uri
        if (id > 0) {
          uri = `/timelines/${id}`
        }
        else {
          uri = '/timelines'
        }
        query.limit = this.limit
        this.$root.api().get(uri, {
          params: query
        }).then(response => {
          response.data.timelines.forEach(timeline => {
            timeline.comments = []
            this.timelines.push(timeline)
            this.fetchComments(timeline)
          })
        })
      },
      reload (query, id) {
        this.clear()
        this.load(query, id)
      }
    },
    mounted () {
      if (!this.id) {
        window.addEventListener('scroll', (e) => {
          if (this.timelines.length > 1) {
            var height = document.documentElement.offsetHeight
            var offset = document.documentElement.scrollTop + window.innerHeight
            if (height === offset) {
              this.load({
                offsetId: this.timelines[this.timelines.length - 1].id
              })
            }
          }
        })
      }
      this.load(this.query, this.id)
    }
  }
</script>

<style scoped>

</style>
