<template>
  <div :class="{
      'alert-warning': warning, 'alert-success': success, 'show': !hide
    }" class="alert alert-dismissible fade" role="alert">
    asdfasdf
    <div v-if="html" v-html="html"></div>
    <slot v-else></slot>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
  export default {
    name: 'Alert',
    props: {
      success: {
        type: Boolean,
        default () {
          return true
        }
      },
      warning: {
        type: Boolean
      },
      dismiss: {
        type: Number
      },
      hide: {
        type: Boolean
      }
    },
    data () {
      return {
        html: null
      }
    },
    methods: {
      open (html) {
        if (html) {
          this.html = html
        }
        alert(html)
        $(this.$el).alert()
      }
    },
    mounted () {
      if (this.dismiss > 0) {
        setTimeout(() => {
          $(this.$el).alert('dispose')
        }, this.dismiss)
      }
    }
  }
</script>

<style scoped>
  .alert {
    position: fixed;
    top: 30px;
    left: 15px;
    width: calc(100% - 30px);
  }
</style>
