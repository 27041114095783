<template>
  <app-layout id="pictures" title="Pictures" back>
    <pictures :curriculum-id="$route.params.id" :filters="$route.query"></pictures>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Pictures from '../components/Pictures'
  export default {
    name: 'PicturesRoute',
    components: {
      AppLayout,
      Pictures
    }
  }
</script>

<style scoped>

</style>
