<template>
  <wheel ref="wheel" :height="500" :display-count="5" :radius="100" padding vertical class="articles" guide-line="none">
    <div v-show="loaded" v-if="schedules.length < 1" style="line-height: 100px; font-size: 23px;">예정된 일정이 없습니다.</div>
    <div v-for="schedule in schedules" class="article">
      <div class="overflow-hidden">
        <router-link :to="'/schedules/' + schedule.id" class="text-white">
          <div v-if="schedule.start_at.substr(0, 10) === schedule.end_at.substr(0, 10)" class="date">
            {{schedule.start_at.substr(0, 10).replace(/-/g, '.')}}
          </div>
          <div v-else class="date">{{schedule.start_at.substr(0, 10).replace(/-/g, '.')}}~{{schedule.end_at.substr(0, 10).replace(/-/g, '.')}}</div>
          <div class="subject">{{schedule.title}}</div>
        </router-link>
      </div>
    </div>
  </wheel>
</template>

<script>
  import Wheel from './Wheel';
  export default {
    name: 'WheelSchedules',
    components: {
      Wheel
    },
    data () {
      return {
        schedules: [],
        loaded: false
      }
    },
    mounted () {
      this.$root.api().get(`/schedules`, {
        params: {
          scheduled: true
        }
      }).then(response => {
        const current = moment().unix()
        let minDiff = 2147451247
        let minDiffStep = 0
        this.schedules = response.data.schedules
        this.schedules.forEach((schedule, index) => {
            let diff = Math.abs(current - moment(schedule.start_at).unix())
            if (minDiff > diff) {
              minDiff = diff
              minDiffStep = index
            }
        })
        this.$nextTick(() => {
          this.$refs.wheel.load(minDiffStep)
          this.loaded = true
        })
      })
    }
  }
</script>

<style lang="scss" scoped>
  .article:before {
    content: '';
    display: block;
    position: absolute;
    top: 38px;
    left: -20px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #682327;
  }
  .article .subject {
    position: relative;
    overflow: visible;
    font-size: 24px;
    font-weight: bold;
    line-height: 38px;
    height: 76px;
  }
</style>
