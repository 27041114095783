import axios from 'axios'

export default {
  data () {
    return {
      curriculumProcesses: {
        PLAN: '기획',
        RECRUIT: '모집중',
        PROGRESS: '진행중',
        END: '종료',
        CANCEL: '취소'
      }
    }
  },
  methods: {
    getCodes (category) {
      return this.$root.api().get(`/codes`, {
        params: {
          category: category
        }
      })
    }
  }
}
