<template>
  <dashboard-layout id="dashboard" title="Dashboard">
    <div class="lecture">
      <div class="heading">{{$root.user.name}}의<br/>수강중 목록입니다</div>
      <div class="course">
        <carousel v-if="curriculums.length > 0" id="lectures" :control-next="curriculums.length > 1">
          <div v-for="(curriculum, index) in curriculums" :class="{active: index === 0}" class="carousel-item">
            <router-link :to="'/curriculums/' + curriculum.id" class="text-white d-block">
              <div class="title">{{curriculum.title}}</div>
              <div v-if="curriculum.start_at && curriculum.end_at" class="period">
                <strong>{{curriculum.start_at.substr(0, 10).replace(/-/g, '.')}}~{{curriculum.end_at.substr(0, 10).replace(/-/g, '.')}}</strong>
              </div>
            </router-link>
          </div>
        </carousel>
        <div v-else style="font-size: 25px;">수강중인 과정이 없습니다.</div>
      </div>
    </div>
    <div class="timeline">
      <div class="tabs d-flex justify-content-between">
        <button @click="selectedTab = 'timeline'" :class="{'btn-primary active': selectedTab === 'timeline', 'btn-secondary': selectedTab !== 'timeline'}" type="button" class="btn mr-1" style="width: 50%;">
          <div>공지사항</div>
        </button>
        <button @click="selectedTab = 'schedule'" :class="{'btn-primary active': selectedTab === 'schedule', 'btn-secondary': selectedTab !== 'schedule'}" type="button" class="btn ml-1" style="width: 50%;">
          <div>강의일정</div>
        </button>
      </div>
      <div>
        <wheel-schedules v-if="selectedTab === 'schedule'"></wheel-schedules>
        <wheel-timelines v-else></wheel-timelines>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
  import DashboardLayout from '../layouts/DashboardLayout'
  import Carousel from '../components/Carousel'
  import WheelTimelines from '../components/WheelTimelines'
  import WheelSchedules from '../components/WheelSchedules'
  export default {
    name: 'DashboardRoute',
    components: {
      DashboardLayout,
      WheelTimelines,
      WheelSchedules,
      Carousel
    },
    data () {
      return {
          curriculums: [],
          selectedTab: 'timeline'
      }
    },
    mounted () {
      this.$root.api().get(`/curriculums`).then(response => {
        this.curriculums = response.data.curriculums
      })
    }
  }
</script>

<style lang="scss" scoped>
  @media (max-width: 340px) {
    .heading {
      font-size: 33px;
      font-weight: bold;
      line-height: 56px;
    }
  }
  @media (min-width: 340px) {
    .heading {
      font-size: 35px;
      font-weight: bold;
      line-height: 56px;
    }
  }
  @media (min-width: 380px) {
    .heading {
      font-size: 36px;
      font-weight: bold;
      line-height: 56px;
    }
  }
  .course {
    min-height: 72px;
  }
  .lecture {
    margin-top: 50px;
    & .course {
      margin-top: 28px;
      & .title {
        font-size: 25px;
      }
      & .period {
        font-size: 16px;
      }
    }
  }
  .timeline {
    margin-top: 180px;
    .articles {
      height: 300px;
      overflow: hidden;
      margin-left: -21px;
    }
    .tabs {
      .active {
        font-size: 18px;
      }
    }
  }
</style>
