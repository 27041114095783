<template>
  <div>
    <div class="card">
      <div class="row">
        <div class="col">
          <span :class="{'bg-white text-burgundy': curriculum.process === 'RECRUIT'}"
                class="badge badge-pill badge-primary border border-burgundy mt-2" style="font-size: 13px;">
          {{curriculumProcesses[curriculum.process]}}
        </span>
        </div>
        <div class="col text-right">
          <button @click.prevent="$refs.alarm.openCurriculum(curriculum.id)" type="button" class="btn btn-clock">알람</button>
        </div>
      </div>
      <h3 class="text-center font-weight-bold">{{curriculum.title}}</h3>
      <div class="description d-flex justify-content-center">
        <div>{{curriculum.start_at.substr(0, 10).replace(/-/g, '.')}}~{{curriculum.end_at.substr(0, 10).replace(/-/g, '.')}}</div>
      </div>
      <div v-if="false" class="review mt-4 text-center">
        <span>강의 평점</span>
        <div v-if="average" class="score font-weight-lighter text-primary mt-1">{{average}}</div>
        <div v-else class="score font-weight-lighter text-primary mt-1">-</div>
      </div>
      <div class="text-center" v-html="curriculum.introduce"></div>
      <!-- <hr class="mt-5 content-related-information"/> -->
      <div class="d-flex justify-content-center mt-3 text-center font-weight-bold">
        <router-link :to="'/curriculums/' + curriculum.id + '/pictures/'" style="width: 100px; font-size: 12px;">
          <div class="border border-burgundy m-2 py-4 rounded">사진</div>
        </router-link>
        <router-link :to="'/curriculums/' + curriculum.id + '/students'" style="width: 100px; font-size: 12px;">
          <div class="border border-burgundy m-2 py-4 rounded">수강생</div>
        </router-link>
        <router-link :to="'/curriculums/' + curriculum.id + '/homework'" style="width: 100px; font-size: 12px;">
          <div class="border border-burgundy m-2 py-4 rounded">과제</div>
        </router-link>
      </div>
      <div class="row mt-4">
        <div class="col pr-1">
          <button @click="showTimelines" type="button" :class="{'btn-primary': selectedTab === 'timelines', 'btn-outline-primary': selectedTab !== 'timelines'}" class="btn d-block w-100 py-2">타임라인</button>
        </div>
        <div class="col pl-1">
          <button @click="showSchedules" type="button" :class="{'btn-primary': selectedTab === 'schedules', 'btn-outline-primary': selectedTab !== 'schedules'}" class="btn d-block w-100 py-2">강의일정</button>
        </div>
      </div>
      <div v-if="selectedTab === 'timelines'" class="mt-4">
        <div class="font-weight-bold">타임라인 작성</div>
        <textarea v-model="timelineContents" class="form-control mt-1"></textarea>
        <button @click="saveTimeline" class="input-group-append btn btn-primary d-block w-100 mt-2" type="button">쓰기</button>
      </div>
    </div>
    <timelines v-if="selectedTab === 'timelines'" ref="timelines" :query="{category: 'curriculums', category_id: id}"></timelines>
    <schedules v-if="selectedTab === 'schedules'" ref="schedules" :query="{curriculum_id: id, scheduled: true}" expandable></schedules>
    <alarm-modal ref="alarm"></alarm-modal>
  </div>
</template>

<script>
  import codes from './Codes.mixin'
  import AlarmModal from './AlarmModal'
  import Timelines from './Timelines'
  import Schedules from './Schedules'
  export default {
    name: 'Curriculum',
    mixins: [
      codes
    ],
    components: {
      AlarmModal,
      Timelines,
      Schedules
    },
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        curriculum: {
          start_at: '',
          end_at: ''
        },
        average: '-',
        timelineContents: null,
        selectedTab: 'timelines'
      }
    },
    methods: {
      saveTimeline () {
        this.$root.api().post(`/timelines`, {
          contents: this.timelineContents,
          category: 'curriculums',
          category_id: this.id
        }).then(response => {
          this.timelineContents = null
          this.$refs.timelines.reload({
            category: 'curriculums',
            category_id: this.id
          })
        })
      },
      showTimelines () {
        this.selectedTab = 'timelines'
      },
      showSchedules () {
        this.selectedTab = 'schedules'
      }
    },
    mounted () {
      this.$root.api().get(`/curriculums/${this.id}`).then(response => {
        this.curriculum = response.data.curriculum
        this.average = response.data.average
      })
    }
  }
</script>

<style scoped>

</style>
