<template>
  <div class="layout-dashboard">
    <header class="row">
      <div style="width: 32px;">
        <button @click="activedMenu = 'menu'" class="btn-hamburger" type="button">Menu</button>
      </div>
      <router-link to="/profile" class="text-white profile col text-right d-flex align-items-center justify-content-end pr-0">
        <profile-image :src="$root.profileImage" size="48px" display="inline-block" margin="0"></profile-image>
        <span class="name">{{$root.user.name}}</span>
        <span class="separator">|</span>
        <strong class="position">{{$root.user.position}}</strong>
      </router-link>
    </header>
    <transition name="fade">
      <div v-if="activedMenu" @click="activedMenu = null" class="menu-background"></div>
    </transition>
    <section>
      <slot></slot>
    </section>
    <transition name="slide">
      <app-menu :active="activedMenu === 'menu'"></app-menu>
    </transition>
  </div>
</template>

<script>
  import AppMenu from '../components/AppMenu'
  import ProfileImage from '../components/ProfileImage'
  export default {
    name: 'DashboardLayout',
    components: {
      AppMenu,
      ProfileImage
    },
    props: {
      id: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        activedMenu: null
      }
    },
    mounted () {
      document.title = `${this.title} | Innofit`
      let triggerScroll = 35
      // is iphone
      if (this.$root.isIphoneX()) {
        $(document.body).addClass('iphone-x')
        triggerScroll = 5
      }
      $(window).on('scroll', () => {
        if (window.scrollY > triggerScroll) {
          $('.layout-dashboard').addClass('fix-top')
        } else {
          $('.layout-dashboard').removeClass('fix-top')
        }
      })
    },
    beforeDestroy() {
      $(window).unbind('scroll')
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../sass/app';
  header {
    padding: 0;
    margin: 0 !important;
    width: 100%;
    height: 48px;
    align-items: center;
  }
  .layout-dashboard.fix-top {
    padding-top: 95px;
  }
  .layout-dashboard.fix-top header {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 69px;
    padding-left: 21px;
    padding-right: 21px;
    background-color: rgba(251, 251, 251, 0.3);
    border-bottom: solid rgba(222, 222, 222, 0.3) 1px;
  }
  .layout-dashboard.fix-top header:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: -1;
  }
  .menu-background {
    height: 100vh;
    width: 100vw;
    background-color: #000000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    &.fade-enter-active {
      transition: all .3s ease;
    }
    &.fade-enter-active {
      transition: all .3s ease;
    }
    &.fade-enter {
      opacity: 0;
    }
    &.fade-leave-active {
      transition: all .3s ease;
    }
    &.fade-leave-to {
      opacity: 0;
    }
  }
  .btn-hamburger {
    background-color: transparent;
    background-size: cover;
    border: none;
    width: 32px;
    height: 28.5px;
    text-indent: -100px;
    color: transparent;
    background-image: url('/img/hamburger_white.svg');
  }
  .content {
    margin-top: 50px;

    & > h2 {
      font-size: 51px;
      font-weight: bold;
      text-align: center;
      line-height: 1.5;
      margin: 0;
      -webkit-font-smoothing: antialiased;
    }
  }
  .layout-dashboard {
    padding: 48px 21px 57px 21px;
    background-image: url('/img/mypage/top_background.png');
    background-position-x: center;
    background-size: 640px;
    background-repeat: no-repeat;
    background-color: $burgundy;
    color: #ffffff !important;

    & > header {
      margin-top: 0;
      & > .profile {
        float: right;
        & > .name {
          margin-left: 10px;
        }
        & > .separator {
          margin-left: 5px;
        }
        & > .position {
          margin-left: 5px;
        }
      }
    }
  }
</style>

<style>
  body.iphone-x .layout-app.fix-top header {
    padding-top: 30px;
    height: 100px;
  }
</style>
