<template>
  <div class="layout-app">
    <header class="row">
      <div style="width: 32px;">
        <button v-if="back === true" @click="$router.go(-1)" type="button" class="btn-back">Back</button>
        <router-link v-else-if="back" :to="back" class="btn-back">Back</router-link>
        <button v-else @click="activedMenu = 'menu'" class="btn-hamburger" type="button">Menu</button>
      </div>
      <h1 class="title col mb-0">{{title}}</h1>
      <div v-if="searchable || (!back && $route.path.match(/^\/(calendar|curriculums|lectures|lecturers)/))" style="width: 32px;">
        <button @click="activedMenu = 'search'" class="btn-magnifier" type="button">Search</button>
      </div>
      <div v-else style="width: 32px;"></div>
    </header>
    <transition name="fade">
      <div v-if="activedMenu" @click="activedMenu = null" class="menu-background"></div>
    </transition>
    <section class="content">
      <slot></slot>
    </section>
    <transition name="slide">
      <app-menu :active="activedMenu === 'menu'"></app-menu>
    </transition>
    <transition name="slide">
      <div v-if="activedMenu === 'search'" @scroll.stop="" class="search">
        <div style="margin-top: 80px;">
          <div class="input-group m-auto" style="width: calc(100% - 30px);">
            <input v-model="searchKeyword" @keypress.enter="search" type="text" class="form-control bg-white border-0 rounded-0 p-2 px-4" style="font-size: 20px;"/>
            <div class="input-group-append">
              <span class="input-group-text rounded-0 bg-white border-0">
                <input @click="search" type="image" src="/img/magnifier.svg"/>
              </span>
            </div>
          </div>
        </div>
        <ul v-if="$route.path.match(/^\/calendar/)" class="mt-5">
          <li>
            <router-link to="/calendar" :class="{active: $route.path.match(/^\/calendar/)}" style="font-size: 25px;">내 일정</router-link>
          </li>
          <!-- <li>
            <router-link to="/calendar" style="font-size: 25px;">월 목록</router-link>
          </li> -->
          <li>
            <router-link :to="'/week/' + today" style="font-size: 25px;">주 목록</router-link>
          </li>
          <li>
            <router-link to="/alarms" :class="{active: $route.path.match(/^\/alarms/)}" style="font-size: 25px;">알람관리</router-link>
          </li>
        </ul>
        <ul v-else class="mt-5">
          <li @click="activedMenu = null">
            <router-link :to="$route.path.replace(/^\/([^/?]+)/, '/$1')" style="font-size: 25px;">전체보기</router-link>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
  import AppMenu from '../components/AppMenu'
  export default {
    name: 'AppLayout',
    components: {
      AppMenu
    },
    props: {
      id: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      back: {
        type: [Boolean, String]
      },
      searchable: {
        type: Boolean
      }
    },
    data () {
      return {
        activedMenu: null,
        searchKeyword: '',
        today: moment().format('YYYYMMDD')
      }
    },
    methods: {
      search () {
        let controller = location.pathname.substr(1).split('/')[0]
        location.href = `/${controller}?keyword=${this.searchKeyword}`
        activedMenu: null
      }
    },
    mounted () {
      document.title = `${this.title} | Innofit`
      let triggerScroll = 35
      // is iphone
      if (this.$root.isIphoneX()) {
        $(document.body).addClass('iphone-x')
        triggerScroll = 5
      }
      $(window).on('scroll', () => {
        if (window.scrollY > triggerScroll) {
          $('.layout-app').addClass('fix-top')
        } else {
          $('.layout-app').removeClass('fix-top')
        }
      })
    },
    beforeDestroy() {
      $(window).unbind('scroll')
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../sass/app';
  header {
    padding: 0;
    margin: 0 !important;
    width: 100%;
    align-items: center;
  }
  .layout-app.fix-top {
    margin-top: 29px;
  }
  .layout-app.fix-top header {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 68px;
    padding-left: 21px;
    padding-right: 21px;
    background-color: rgba(251, 251, 251, 0.3);
    border-bottom: solid rgba(222, 222, 222, 0.8) 1px;
  }
  .layout-app.fix-top header:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: -1;
    -webkit-box-shadow: 0px 3px 12px -7px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 12px -7px rgba(0,0,0,0.75);
    box-shadow: 0px 3px 12px -7px rgba(0,0,0,0.75);
  }
  .layout-app {
    padding: 57px 21px;
  }
  .menu-background {
    height: 100vh;
    width: 100vw;
    background-color: #000000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    &.fade-enter-active {
      transition: all .3s ease;
    }
    &.fade-enter-active {
      transition: all .3s ease;
    }
    &.fade-enter {
      opacity: 0;
    }
    &.fade-leave-active {
      transition: all .3s ease;
    }
    &.fade-leave-to {
      opacity: 0;
    }
  }
  .btn-hamburger {
    background-color: transparent;
    background-size: cover;
    border: none;
    width: 32px;
    height: 28.5px;
    text-indent: -100px;
    color: transparent;
    background-image: url('/img/hamburger.svg');
  }
  .btn-back {
    background-color: transparent;
    background-size: contain;
    border: none;
    width: 32px;
    height: 28.5px;
    text-indent: -100px;
    color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('/img/back.png');
  }
  .btn-back:hover {
    text-indent: -100px;
    color: transparent;
  }
  .btn-magnifier {
    background-color: transparent;
    background-size: cover;
    border: none;
    width: 32px;
    height: 32px;
    text-indent: -100px;
    color: transparent;
    background-image: url('/img/magnifier.svg');
  }
  .menu, .search {
    height: 100vh;
    width: calc(100vw - 50px);
    background-color: $burgundy;
    color: #ffffff;
    position: fixed;
    top: 0;
    z-index: 1000;
    overflow: scroll;

    & a {
      color: $white;
    }

    .slide-enter-active {
      transition: all .3s ease;
    }
    &.slide-enter-active {
      transition: all .3s ease;
    }
    &.slide-leave-active {
      transition: all .3s ease;
    }
    & > .profile {
      text-align: center;
      margin-top: 80px;
      & > a {
        & > img {
          width: 117px;
        }
      }
      & > .description {
        margin-top: 20px;
        font-size: 20px;
        & > .separator {
          margin-left: 5px;
        }
        & > .job {
          margin-left: 5px;
        }
      }
    }
    & > ul {
      margin: 50px 0 0 0;
      & > li {

      }
      a {
        display: block;
        font-size: 28px;
        font-weight: bold;
        padding: 20px;
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
        &:active {
          background-color: rgba(0, 0, 0, 0.2);
        }
        &.active {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  .menu {
    left: 0;
    &.slide-enter {
      transform: translateX(-90vw);
    }
    &.slide-leave-to {
      transform: translateX(-90vw);
    }
  }
  .search {
    right: 0;
    &.slide-enter {
      transform: translateX(90vw);
    }
    &.slide-leave-to {
      transform: translateX(90vw);
    }
  }
  .content {
    margin-top: 30px;

    & > h2 {
      font-size: 51px;
      font-weight: bold;
      text-align: center;
      line-height: 1.5;
      margin: 0;
      -webkit-font-smoothing: antialiased;
    }
  }
</style>

<style>
  body.iphone-x .layout-dashboard.fix-top header {
    padding-top: 30px;
    height: 100px;
  }
</style>
