<template>
  <modal ref="modal" :title="comment ? '댓글수정' : '댓글작성'" @confirm="save" @close="timeline = null">
    <div class="form-group">
      <textarea v-model="contents" ref="contents" class="form-control" rows="3" placeholder="내용을 입력해 주세요"></textarea>
    </div>
  </modal>
</template>

<script>
  import Modal from './Modal'
  export default {
    name: 'TimelineCommentWriter',
    components: {
      Modal
    },
    data () {
      return {
        timeline: null,
        comment: null,
        contents: null
      }
    },
    methods: {
      open (timeline, comment) {
        this.timeline = timeline
        this.comment = comment
        if (comment) {
          this.contents = comment.contents
        } else {
          this.contents = null
        }
        this.$refs.modal.open()
      },
      close () {
        this.timeline = null
        this.comment = null
        this.contents = null
        this.$refs.modal.close()
      },
      save () {
        if (!this.contents) {
          alert('내용을 작성해 주세요');
          this.$refs.contents.focus()
          return;
        }

        if (this.comment) {
          this.$root.api().put(`/timelines/comments/${this.comment.id}`, {
            timeline_id: this.timeline.id,
            contents: this.contents
          }).then(response => {
            this.$emit('save', this.timeline, response.data)
            this.close()
          })
        }
        else {
          this.$root.api().post(`/timelines/comments`, {
            timeline_id: this.timeline.id,
            contents: this.contents
          }).then(response => {
            this.$emit('save', this.timeline, response.data)
            this.close()
          })
        }
      }
    }
  }
</script>

<style scoped>

</style>
