<template>
  <app-layout id="alarm" title="Alarm" back>
    <alarms></alarms>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Alarms from '../components/Alarms'
  export default {
    name: 'AlarmsRoute',
    components: {
      Alarms,
      AppLayout
    }
  }
</script>

<style scoped>

</style>
