<template>
  <div :id="id" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <slot></slot>
    </div>
    <a v-if="controls || controlPrev" class="carousel-control-prev" :href="'#' + id" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a v-if="controls || controlNext" class="carousel-control-next" :href="'#' + id" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
  export default {
    name: 'Carousel',
    props: {
      id: {
        type: String,
        required: true
      },
      controls: {
        type: Boolean,
        required: false,
        default () {
          return false
        }
      },
      controlPrev: {
        type: Boolean,
        required: false,
        default () {
          return false
        }
      },
      controlNext: {
        type: Boolean,
        required: false,
        default () {
          return false
        }
      }
    },
    mounted () {
      $(this.$el).carousel()
    }
  }
</script>

<style scoped>

</style>
