import { render, staticRenderFns } from "./HomeworkRoute.vue?vue&type=template&id=af838bae&scoped=true&"
import script from "./HomeworkRoute.vue?vue&type=script&lang=js&"
export * from "./HomeworkRoute.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af838bae",
  null
  
)

export default component.exports