<template>
  <div>
    <ul>
      <li class="course card text-center">
        <div class="d-flex justify-content-between align-items-center">
          <div class="text-right tutor">
            <span class="job">강사</span>
            <span class="separator">|</span>
            <strong class="name">{{schedule.lecturer_name}}</strong>
          </div>
          <button @click.prevent="$refs.alarm.open(schedule.id)" type="button" class="btn btn-clock">알람</button>
        </div>
        <div class="subject text-left">
          <h3 class="title font-weight-bold mt-4">{{schedule.title}}</h3>
        </div>
        <div class="text-left mt-3">
          <div v-if="schedule.start_at && schedule.end_at">
            날짜 |
            <strong>{{scheduleDate(schedule.start_at)}}</strong>
          </div>
          <div v-if="schedule.start_at && schedule.end_at">
            시간 |
            <strong>{{schedule.start_at.substr(11, 5)}} ~ {{schedule.end_at.substr(11, 5)}}</strong>
          </div>
          <div>
            장소 |
            <strong>{{schedule.place}}</strong>
          </div>
        </div>
        <div v-if="false" class="review">
          <div class="title mt-4">강의 평점</div>
          <div v-if="lecture.average > 0" class="score font-weight-lighter text-primary">{{lecture.average}}</div>
          <div v-else class="score font-weight-lighter text-primary">-</div>
        </div>
        <div class="mt-3 text-left">
          <div class="curriculum-title font-weight-bold">{{schedule.curriculum_title}}</div>
          <div v-html="schedule.description" class="description mt-1"></div>
        </div>
<!--        <hr class="mt-5 content-related-information"/>-->
        <div class="row justify-content-center mt-3">
<!--          <router-link :to="'/schedules?category=' + schedule.lecture_category" class="col-3 border border-burgundy m-2 ml-4 py-4 px-1 rounded" style="font-size: 12px;">-->
<!--            같은 주제-->
<!--          </router-link>-->
<!--          <router-link :to="'/schedules?lecturer_id=' + schedule.lecturer_id" class="col-3 border border-burgundy m-2 py-4 px-1 rounded" style="font-size: 12px;">-->
<!--            같은 강사-->
<!--          </router-link>-->
<!--          <router-link :to="'/schedules?curriculum_id=' + schedule.curriculum_id" class="col-3 border border-burgundy m-2 mr-4 py-4 px-1 rounded" style="font-size: 12px;">-->
<!--            같은 과정-->
<!--          </router-link>-->
        </div>
      </li>
      <li v-for="(_schedule, index) in schedules" :key="index" class="course card">
        <a :href="'/schedules/' + _schedule.id">
          <div class="text-right tutor">
            <span class="job">강사</span>
            <span class="separator">|</span>
            <strong class="name">{{_schedule.lecturer_name}}</strong>
          </div>
          <h3 class="title mt-4" style="font-size: 30px;">
            {{_schedule.lecture_title}}
            <button @click.prevent="$refs.alarm.open(_schedule.id)" type="button" class="btn btn-clock">알람</button>
          </h3>
          <div v-html="_schedule.lecture_description"></div>
          <div v-if="_schedule.start_at && _schedule.end_at" class="mt-3">
            시간 |
            <strong>{{_schedule.start_at.substr(11, 5)}} ~ {{_schedule.end_at.substr(11, 5)}}</strong>
          </div>
          <div>
            장소 |
            <strong>{{_schedule.place}}</strong>
          </div>
        </a>
      </li>
    </ul>
    <alarm-modal ref="alarm"></alarm-modal>
  </div>
</template>

<script>
  import AlarmModal from './AlarmModal'
  import WeeklyDates from './WeeklyDates'
  export default {
    name: 'Schedule',
    components: {
      WeeklyDates,
      AlarmModal
    },
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        schedule: {
          start_at: '',
          end_at: '',
        },
        lecture: {},
        schedules: [],
        relatedLecture: {},
        otherSchedule: {},
        otherCurriculum: {}
      }
    },
    methods: {
      scheduleDate (date) {
        return moment(date).format('YYYY-MM-DD(ddd)')
      }
    },
    mounted () {
      this.$root.api().get(`/schedules/${this.id}`).then(response => {
        this.schedule = response.data.schedule
        this.lecture = response.data.lecture
        // this.schedules = response.data.schedules
        this.relatedLecture = response.data.relatedLecture
        this.otherSchedule = response.data.otherSchedule
        this.otherCurriculum = response.data.otherCurriculum
      })
    }
  }
</script>

<style lang="scss" scoped>
  h2 {
    font-size: 51px;
    font-weight: bold;
    text-align: center;
    line-height: 1.5;
    margin: 0;
    -webkit-font-smoothing: antialiased;
  }
</style>
