<template>
  <div>
    <h3 v-if="thisWeek[3]"
      class="text-center text-burgundy font-weight-bold"
      style="font-size: 40px;"
    >{{active.format('M')}}월</h3>
    <div class="weekly-dates">
      <div class="week-cell"></div>
      <div v-for="day in thisWeek" :key="day.weekday()" class="week-cell">
        <div class="week-day">{{weekdays[day.weekday()]}}</div>
      </div>
      <div class="week-cell"></div>
      <div class="week-cell">
        <div class="day">
          <router-link v-if="start"
            :to="'/week/' + start.clone().subtract(1, 'days').format('YYYYMMDD')"
            replace
          >&lt;</router-link>
          <div class="dots"></div>
        </div>
      </div>
      <div v-for="(date, index) in thisWeek" :key="date.format('YYYYMMDD')" class="week-cell">
        <div class="day" :class="{'active': active && active.toDate().getDay() === index}">
          <router-link
            :to="'/week/' + date.format('YYYYMMDD')" replace
          >{{date.format('D')}}</router-link>
          <div class="dots">
            <span v-for="(dot, index) in dots.filter(d => d === date.format('YYYYMMDD'))" :key="index" class="dot"></span>
          </div>
        </div>
      </div>
      <div class="week-cell">
        <div class="day">
          <router-link v-if="end"
            :to="'/week/' + end.clone().add(1, 'days').format('YYYYMMDD')"
            replace
          >&gt;</router-link>
          <div class="dots"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WeeklyDates',
    props: {
      date: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        weekdays: ['일', '월', '화', '수', '목', '금', '토'],
        thisWeek: [],
        dots: [],
        start: null,
        end: null,
        active: null
      }
    },
    watch: {
      date (newDate, fromDate) {
        this.load(newDate)
      }
    },
    methods: {
      load (date) {
        this.active = moment(date)
        this.thisWeek.splice(0)
        this.start = this.active.clone().subtract(this.active.toDate().getDay(), 'days')
        this.end = this.start.clone().add(6, 'days')

        this.thisWeek.push(this.start)
        this.thisWeek.push(this.start.clone().add(1, 'days'))
        this.thisWeek.push(this.start.clone().add(2, 'days'))
        this.thisWeek.push(this.start.clone().add(3, 'days'))
        this.thisWeek.push(this.start.clone().add(4, 'days'))
        this.thisWeek.push(this.start.clone().add(5, 'days'))
        this.thisWeek.push(this.end)

        this.$root.api().get(`/schedules`, {
          params: {
            scheduled: true,
            start: this.start.format('YYYYMMDD'),
            end: this.end.format('YYYYMMDD')
          }
        }).then(response => {
          this.dots.splice(0)
          response.data.schedules.forEach((schedule, index) => {
            if (index < 4) {
              this.dots.push(moment(schedule.start_at).format('YYYYMMDD'))
            }
          })
        })
      }
    },
    mounted () {
      this.load(this.date)
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../sass/variables';
  // 스케쥴 상세 상단
  .weekly-dates {
    grid-template-columns: repeat(9, 1fr);
    row-gap: 0px;
    column-gap: 0px;
    padding: 5px 6px 7px 6px;
    margin-top: 20px;
    flex-shrink: 1;
    flex-grow: 1;
    display: grid;
    position: relative;
    & > .week-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      & > .week-day {
        font-size: 20px;
        padding: 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        font-weight: 700;
        -webkit-font-smoothing: antialiased;
      }
      & > .day {
        position: relative;
        font-size: 20px;
        padding: 5px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        font-weight: 700;
        color: #707070;
        -webkit-font-smoothing: antialiased;
        &.active {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: calc(50% - 20px);
            display: block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            background-color: $burgundy;
            border-radius: 50%;
            z-index: -1;
          }
          a {
            color: #ffffff;
            line-height: 30px;
          }
        }
        .dots {
          display: flex;
          height: 5px;
          .dot {
            display: block;
            background-color: $burgundy;
            width: 5px;
            height: 5px;
            border-radius: 2.5px;
            bottom: 12px;
            margin: 0 2px;
          }
        }
      }
    }
  }
</style>
