<template>
  <modal ref="modal" title="알람 설정" @confirm="confirm">
    <template>
      <div class="text-center">
        <img src="/img/clock.svg" width="100"/>
      </div>
      <div class="font-weight-bold mt-4" style="font-size: 20px;">강의 시작 전</div>
      <div class="d-flex justify-content-between flex-wrap mt-2">
        <button @click="alarm.time = '1week'" :class="{
            'btn-primary': alarm.time === '1week',
            'btn-outline-secondary': alarm.time !== '1week'
          }" type="button" class="btn flex-fill m-1">일주일 전</button>
        <button @click="alarm.time = '1day'" :class="{
            'btn-primary': alarm.time === '1day',
            'btn-outline-secondary': alarm.time !== '1day'
          }" type="button" class="btn flex-fill m-1">1일 전</button>
        <button @click="alarm.time = '2hour'" :class="{
            'btn-primary': alarm.time === '2hour',
            'btn-outline-secondary': alarm.time !== '2hour'
          }" type="button" class="btn flex-fill m-1">2시간 전</button>
        <button @click="alarm.time = '1hour'" :class="{
            'btn-primary': alarm.time === '1hour',
            'btn-outline-secondary': alarm.time !== '1hour'
          }" type="button" class="btn flex-fill m-1">1시간 전</button>
        <button @click="alarm.time = '30minute'" :class="{
            'btn-primary': alarm.time === '30minute',
            'btn-outline-secondary': alarm.time !== '30minute'
          }" type="button" class="btn flex-fill m-1">30분 전</button>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal'
  export default {
    name: 'AlarmModal',
    components: {
      Modal
    },
    data () {
      return {
        alarm: {
          scheduleId: null,
          curriculumId: null,
          time: null
        },
        warn: false
      }
    },
    methods: {
      open (scheduleId) {
        this.alarm = this.$options.data().alarm
        this.warn = false
        this.$root.api().get(`/alarms`, {
          params: {
            scheduleId: scheduleId
          }
        }).then(response => {
          if (response.data.length > 0) {
            this.alarm.id = response.data[0].id
            this.alarm.time = response.data[0].time
          }
          this.alarm.scheduleId = scheduleId
          this.$refs.modal.open()
        })
      },
      openCurriculum (curriculumId) {
        this.alarm = this.$options.data().alarm
        this.warn = false
        this.$root.api().get(`/alarms`, {
          params: {
            curriculumId: curriculumId
          }
        }).then(response => {
          if (response.data.length > 0) {
            this.alarm.id = response.data[0].id
            this.alarm.time = response.data[0].time
            if (response.data[0].time) {
              this.warn = true
            }
          }
          this.alarm.curriculumId = curriculumId
          this.$refs.modal.open()
        })
      },
      confirm () {
        if (this.alarm.id > 0) {
          if (this.warn && !confirm('과정의 모든 일정의 알람설정이 변경됩니다.\r\n계속하시겠습니까?')) {
            return
          }
          this.$root.api().put(`/alarms/${this.alarm.id}`, this.alarm).then(response => {
            if (response.data.alarmId > 0) {
              this.$refs.modal.close()
            }
          })
        } else {
          this.$root.api().post(`/alarms`, this.alarm).then(response => {
            if (response.data.alarmId > 0) {
              this.alarm.id = response.data.alarmId
              this.$refs.modal.close()
            }
          })
        }
      }
    }
  }
</script>

<style scoped>

</style>
