<template>
  <app-layout id="curriculums" title="Curriculums">
    <curriculums></curriculums>
  </app-layout>
</template>

<script>
  import AppLayout from '../layouts/AppLayout'
  import Curriculums from '../components/Curriculums'
  export default {
    name: 'CurriculumsRoute',
    components: {
      AppLayout,
      Curriculums
    }
  }
</script>

<style scoped>

</style>
